import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const ExemptionTypeConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/exemption-type",
    component: React.lazy(() => import("./exemption-type")),
  },
];
