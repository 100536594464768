import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Toolbar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Hidden,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { AiOutlineLogout } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import MenuIcon from "@mui/icons-material/Menu";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import AppNotifications from "../../../AppNotifications";
import {
  logoutAction,
  logoutWithoutToken,
  toggleNavCollapsed,
} from "redux/actions";
import { reqFCMToken } from "shared/helpers/firebase";
import AppTooltip from "@crema/core/AppTooltip";
import { AppState } from "redux/store";
import AppConfirmDialog from "@crema/core/AppConfirmDialog";
import { Fonts } from "shared/constants/AppEnums";

const Root = styled(Toolbar)(({ theme }) => ({
  [theme.breakpoints.down("lg")]: {
    "& .MuiToolbar-root": {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    justifyContent: "flex-end",
  },
}));

interface HeaderProps {
  isCollapsed?: boolean;
}

const AppHeader: React.FC<HeaderProps> = ({ isCollapsed }) => {
  const { sidebarBgColor, sidebarTextColor } = useSidebarContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { userData } = useSelector<AppState, AppState["AuthData"]>(
    ({ AuthData }) => AuthData
  );

  useEffect(() => {}, [userData]);

  const [logoutLoad, setLogoutLoaing] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);

  const logout = async () => {
    const fcmToken = await reqFCMToken();
    if (fcmToken) {
      const data = { token: fcmToken };
      dispatch(logoutAction(data, setLogoutLoaing));
    } else {
      dispatch(logoutWithoutToken());
    }
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        zIndex: 2,
        boxShadow: "none",
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: sidebarBgColor,
        "& .MuiToolbar-root": { minHeight: 60 },
        // width: { xs: "100%" },
      }}
      className="app-bar"
    >
      <Root>
        <Toolbar
          sx={{
            boxSizing: "border-box",
            paddingLeft: { xs: 5 },
            paddingRight: { xs: 5 },
            width: { xs: "100%", lg: "100%" },
            ml: { md: "20px", lg: isCollapsed ? "20px" : "280px" },
          }}
        >
          <Hidden lgUp>
            <IconButton
              sx={{ color: sidebarTextColor }}
              edge="start"
              className="menu-btn"
              color="inherit"
              aria-label="open drawer"
              onClick={() => dispatch(toggleNavCollapsed())}
              size="large"
            >
              <MenuIcon sx={{ width: 35, height: 35 }} />
            </IconButton>
          </Hidden>

          <Box
            sx={{
              fontSize: 16,
              fontWeight: Fonts.BOLD,
              overflow: "hidden",
              textOverflow: "ellipsis",
              WebkitLineClamp: 1,
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
            }}
          >
            Hello! {userData?.name || ""}
          </Box>
          <Box sx={{ flexGrow: 1, ml: 4 }} />
          <Box sx={{ px: 1.85 }}>
            <AppNotifications />
          </Box>
          <AppTooltip title="Profile" placement={"bottom"}>
            <Box sx={{ ml: 4 }}>
              <Box onClick={handleClick}>
                <Avatar
                  variant="rounded"
                  sx={{
                    backgroundColor: sidebarBgColor,
                    cursor: "pointer",
                    border: "1px solid #BDBDBD",
                  }}
                  src={
                    userData?.image ||
                    "https://avatars.dicebear.com/api/identicon/a.svg?background=%23def6fd"
                  }
                />
              </Box>
            </Box>
          </AppTooltip>

          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ py: 4, zIndex: 1000002 }}
          >
            <MenuItem
              sx={{ px: 6, py: 1.5 }}
              onClick={() => {
                handleClose();
                history.push("/my-account");
              }}
            >
              <div
                style={{ fontSize: "20px", padding: "5px 10px", lineHeight: 0 }}
              >
                <BiUser />
              </div>
              My Account
            </MenuItem>
            <MenuItem
              sx={{ px: 6, py: 1.5 }}
              onClick={() => setLogoutModal(true)}
              disabled={logoutLoad}
            >
              {logoutLoad ? (
                <div style={{ padding: "5px 10px", lineHeight: 0 }}>
                  <CircularProgress size={20} />
                </div>
              ) : (
                <div
                  style={{
                    fontSize: "20px",
                    padding: "5px 10px",
                    lineHeight: 0,
                  }}
                >
                  <AiOutlineLogout />
                </div>
              )}
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
        <AppConfirmDialog
          open={logoutModal}
          disabled={logoutLoad}
          loading={logoutLoad}
          onDeny={() => {
            setLogoutLoaing(false);
            setLogoutModal(false);
          }}
          title="Are you sure you want to Logout?"
          onConfirm={logout}
          dialogTitle="Logout"
        />
      </Root>
    </AppBar>
  );
};
export default AppHeader;
