import React, { ReactNode } from "react";
import Button from "@mui/material/Button";
import IntlMessages from "../../utility/IntlMessages";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Slide,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Fonts } from "../../../shared/constants/AppEnums";
import { TransitionProps } from "@mui/material/transitions";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  // eslint-disable-next-line no-undef
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AppConfirmDialogProps {
  dialogTitle: string | ReactNode;
  open: any;
  onDeny: (isOpen: boolean) => void;
  onClose?: any;
  title: string | ReactNode;
  subTitle?: string | ReactNode;
  otherChildrenShow?: any;
  otherChildren?: any;
  from?: string | ReactNode;
  onConfirm: () => void;
  loading: boolean;
  disabled: boolean;
  Ytitle?: string | ReactNode;
  Ntitle?: string | ReactNode;
  type?: any;
  isAlertDialog?: boolean;
}

const AppConfirmDialog: React.FC<AppConfirmDialogProps> = ({
  open,
  onDeny,
  onConfirm,
  title,
  subTitle,
  otherChildrenShow,
  otherChildren,
  dialogTitle,
  loading,
  disabled,
  Ytitle = <IntlMessages id="common.yes" />,
  Ntitle = <IntlMessages id="common.no" />,
  onClose,
  from,
  type,
  isAlertDialog = false,
}) => {
  return (
    <Dialog
      sx={{
        zIndex: 1000002,
        "& .MuiPaper-root": {
          minWidth: "300px",
          maxWidth: "50vh",
          width: "100%",
        },
      }}
      TransitionComponent={Transition}
      open={open}
      onClose={() => null}
    >
      <DialogTitle>
        <Typography
          component="h4"
          variant="h4"
          sx={{ fontWeight: Fonts.SEMI_BOLD }}
          id="alert-dialog-title"
        >
          {dialogTitle}
        </Typography>
        <Box
          sx={{
            height: "2px",
            width: { xs: "30px", md: "40px" },
            backgroundColor: "#0BBCED",
          }}
        />
      </DialogTitle>
      <DialogContent
        sx={{ color: "text.secondary", fontSize: 14 }}
        id="alert-dialog-description"
      >
        {title}
        {subTitle ? (
          <Typography sx={{ mt: 1, fontSize: 12, fontWeight: Fonts.MEDIUM }}>
            {subTitle}
          </Typography>
        ) : null}
        {otherChildrenShow && (
          <Box>
            <Divider sx={{ my: 4 }} />
            {otherChildren()}
          </Box>
        )}
      </DialogContent>
      {isAlertDialog ? (
        <DialogActions sx={{ pb: 4, px: 6 }}>
          <LoadingButton
            disabled={disabled}
            variant="outlined"
            sx={{ fontWeight: Fonts.MEDIUM }}
            onClick={onConfirm}
            color="primary"
            loading={loading}
            size="small"
            autoFocus
            type={type}
          >
            {Ytitle}
          </LoadingButton>
        </DialogActions>
      ) : (
        <DialogActions sx={{ pb: 4, px: 6 }}>
          <LoadingButton
            disabled={disabled}
            variant="outlined"
            sx={{ fontWeight: Fonts.MEDIUM }}
            onClick={onConfirm}
            color="primary"
            loading={loading}
            size="small"
            autoFocus
            type={type}
          >
            {Ytitle}
          </LoadingButton>
          <Button
            size="small"
            variant="outlined"
            sx={{ fontWeight: Fonts.MEDIUM }}
            onClick={() => {
              if (from === "draft") {
                onClose();
              } else {
                onDeny(false);
              }
            }}
            color="secondary"
          >
            {Ntitle}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default AppConfirmDialog;
