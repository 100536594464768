import React from "react";
import { Redirect } from "react-router-dom";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const CorporateListsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/corporate-lists/:id?"],
    component: React.lazy(() => import("./corporate-lists")),
  },
  {
    path: "/corporate-lists",
    component: () => {
      return <Redirect to="/corporate-lists" />;
    },
  },
];

export const DeletedCorporateConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: ["/deleted-corporate/:id?"],
    component: React.lazy(() => import("./deleted-corporate")),
  },
  {
    path: "/deleted-corporate",
    component: () => {
      return <Redirect to="/deleted-corporate" />;
    },
  },
];

export const CorporateDetailsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/corporate-details",
    component: React.lazy(() => import("./corporate-details")),
  },
];
