import React from "react";
import moment from "moment";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemAvatar,
  Popover,
  Typography,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { Delete } from "@mui/icons-material";
import { Fonts } from "../../../shared/constants/AppEnums";
import getApiData from "../../../shared/helpers/apiHelper";
import { toast } from "react-toastify";
import { getBadgeCount, getHelpBadgeCount } from "commonFunction";
import { setBadgeCount } from "redux/actions/BadgeCountData";
import { setHelpBadgeCount } from "redux/actions/HelpBadgeCountData";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store";
import { isEmpty } from "lodash";
interface NotificationItemProps {
  item: any;
  onClose: any;
  getNotifications: (data) => void;
  btnType?: any;
}

const NotificationItem: React.FC<NotificationItemProps> = ({
  item,
  onClose,
  getNotifications,
  btnType,
}) => {
  const history: any = useHistory();
  const dispatch = useDispatch();
  const { badgeCount } = useSelector<AppState, AppState["BadgeCountData"]>(
    ({ BadgeCountData }) => BadgeCountData
  );
  const { helpBadgeCount } = useSelector<
    AppState,
    AppState["HelpBadgeCountData"]
  >(({ HelpBadgeCountData }) => HelpBadgeCountData);

  const [openDltModal, setOpenDltModal] = React.useState<any>(false);
  const [dltLoad, setDltLoad] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  //API For delete single notification
  async function deleteNotification(id) {
    setDltLoad(true);
    try {
      const res = await getApiData(
        `notification/admin/remove-one/${id}`,
        {},
        "DELETE"
      );
      if (res.success) {
        await getNotifications({ page: 1 });
        toast.success(res.message);
        setOpenDltModal(false);
        setAnchorEl(null);
      } else {
        toast.error(res.message);
      }
      setDltLoad(false);
    } catch (error) {
      setDltLoad(false);
      console.log("delete error", error);
      toast.error("Something went wrong");
    }
  }

  //API For read single notification
  async function readOneNotification(item: any) {
    try {
      const res = await getApiData(
        `notification/admin/read/${item?._id}`,
        {},
        "GET"
      );
      if (res.success) {
        if (btnType === "helpnoti") {
          getHelpBadgeCount(dispatch, setHelpBadgeCount, helpBadgeCount);
        } else {
          getBadgeCount(dispatch, setBadgeCount, badgeCount);
        }
        await getNotifications({ page: 1 });
      } else {
        toast.error(res.message);
      }
    } catch (error) {
      console.log("read error", error);
      toast.error("Something went wrong");
    }
  }

  const navigationTo = async (item: any) => {
    readOneNotification(item);
    if (item.title.includes("Request deleted")) {
      return null;
    } else {
      if (isEmpty(item.request_id) && item?.type !== "bank") {
        toast.error("This Request Doesn't Exist");
      } else if (
        item?.type === "ngo" &&
        history?.location?.state !== item.ngo_id
      ) {
        history.push({
          pathname: `/ngo/${item.ngo_id}`,
          state: { ...item, from: "notification" },
        });
        onClose();
      } else if (
        (item?.type === "health" ||
          item?.type === "education" ||
          item?.type === "fundraiser" ||
          item?.type === "hunger" ||
          item?.type === "women-empowerment" ||
          item?.type === "human-rights" ||
          item?.type === "join-request" ||
          item?.type === "disaster-relief" ||
          item?.type === "elderly-care" ||
          item?.type === "bank") &&
        !isEmpty(item.request_id) &&
        history?.location?.state?.id !== item.request_id
      ) {
        history.push({
          pathname: `/manage-requests/${item.request_id}`,
          state: {
            ...item,
            from: "notification",
            id: item?.request_id,
            bankModalOpen: item.title.includes("Added Bank Details")
              ? true
              : false,
          },
        });
        onClose();
      } else if (item?.type === "bank") {
        history.push({
          pathname: `/users-bank-list`,
        });
      } else if (
        item?.type === "profile" &&
        history?.location?.pathname !== "/my-account"
      ) {
        history.push("/my-account");
        onClose();
      } else if (
        item?.type === "fund" &&
        history?.location?.state?.fund_id !== item.fund_id
      ) {
        history.push({
          pathname: `/funds/${item._id}`,
          state: {
            ...item,
            from: "notification",
            id: item.fund_id,
            is_open: item.title.includes("has reported") ? true : false,
          },
        });
        onClose();
      } else if (item?.type === "testimonial") {
        history.push({
          pathname: `/testimonials`,
          // state: { ...item, from: "notification", id: item.fund_id },
        });
        onClose();
      } else if (item?.type === "help-request") {
        history.push({
          pathname: `/help-requests/${item?.request_id}`,
          state: { ...item, from: "notification", id: item.request_id },
        });
        onClose();
      } else if (
        item?.type === "join-drive" ||
        item?.type === "drive" ||
        item?.type === "drive-started" ||
        item?.type === "drive-completed"
      ) {
        history.push({
          pathname: `/drive-lists/${item?.request_id}`,
          state: { ...item, from: "notification", id: item.request_id },
        });
        onClose();
      } else if (item?.type === "start-fund") {
        history.push({ pathname: `/funds/${item?.request_id}` });
        onClose();
      }
    }
  };

  return (
    <ListItem
      sx={{
        padding: "5px 5px 5px 15px",
        cursor: "pointer",
        backgroundColor: !item?.is_read ? "#E5F6FD" : "#fff",
        borderLeft: !item?.is_read ? "4px solid #439BFC" : "0px",
        borderBottom: "0.1px solid #ccc",
        alignItems: "center",
      }}
      className="item-hover"
      onClick={() => {
        if (openDltModal) {
          return null;
        } else {
          navigationTo(item);
        }
      }}
    >
      <ListItemAvatar sx={{ mt: 1 }}>
        <Avatar
          sx={{
            width: 43,
            height: 43,
            "& .MuiAvatar-img": { objectFit: "contain" },
          }}
          variant="rounded"
          alt="Remy Sharp"
          src={"/assets/images/LOGOBLACK.png"}
        />
      </ListItemAvatar>
      <Box
        sx={{
          fontSize: 12,
          color: (theme) => theme.palette.text.secondary,
          width: "100%",
        }}
      >
        <Typography>
          <Box
            component="span"
            sx={{
              fontSize: 13,
              fontWeight: Fonts.MEDIUM,
              mb: 0.5,
              color: (theme) => theme.palette.text.primary,
              mr: 1,
              display: "inline-block",
            }}
          >
            {item?.is_read ? null : (
              <Box
                sx={{
                  position: "absolute",
                  top: 5,
                  left: 5,
                  backgroundColor: "#2F53C8",
                  width: 7,
                  height: 7,
                  borderRadius: "100%",
                }}
              />
            )}
            {item?.title}
          </Box>
          <Typography align="left" sx={{ fontSize: 13, mb: 0.5 }}>
            {item.message}
          </Typography>
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography align="right" sx={{ fontSize: "12px" }}>
            <Box component="span">
              {moment(item?.createdAt).format("DD-MMM-YYYY hh:mm A")}
            </Box>
          </Typography>
          <IconButton
            sx={{
              cursor: "pointer",
              padding: 0,
            }}
            color="warning"
            aria-label="delete"
            size="small"
            onClick={(event) => {
              handleClick(event);
              setOpenDltModal(true);
            }}
          >
            <Delete fontSize="small" />
          </IconButton>
        </Box>
        <Popover
          anchorEl={anchorEl}
          open={openDltModal}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ zIndex: 1000002 }}
        >
          <Box style={{ padding: "20px" }}>
            <Typography variant="h6" component="h2">
              {`Are you sure you want to delete this notification?`}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                disabled={dltLoad}
                size="small"
                variant="outlined"
                onClick={() => deleteNotification(item?._id)}
              >
                {dltLoad ? (
                  <CircularProgress
                    size={14}
                    color="primary"
                    style={{ margin: "5px 0px" }}
                  />
                ) : (
                  "Yes"
                )}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="warning"
                onClick={() => {
                  setDltLoad(false);
                  setAnchorEl(null);
                  setOpenDltModal(false);
                }}
                autoFocus
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Popover>
      </Box>
    </ListItem>
  );
};

export default NotificationItem;
