import React, { ReactNode } from "react";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import { CremaTheme } from "../../../types/AppContextPropsType";

interface AppGridContainerProps {
  children: ReactNode;
  sxStyle?: any;
  [x: string]: any;
}

const AppGridContainer: React.FC<AppGridContainerProps> = ({
  children,
  sxStyle,
  ...others
}) => {
  const isMDDown = useMediaQuery((theme: CremaTheme) =>
    theme.breakpoints.down("md")
  );
  return (
    <Grid
      sx={{ ...sxStyle, width: "100%" }}
      container
      spacing={isMDDown ? 3 : 5}
      {...others}
    >
      {children}
    </Grid>
  );
};

export default AppGridContainer;
