import React from "react";
import { Box, IconButton } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import { SxProps } from "@mui/system";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import { useHistory } from "react-router-dom";
import { initialUrl } from "shared/constants/AppConst";

interface UserInfoProps {
  color?: string;
  sxStyle?: SxProps;
}

const UserInfo: React.FC<UserInfoProps> = ({ sxStyle }) => {
  const { sidebarBgColor } = useSidebarContext();
  const history = useHistory();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          borderBottom: "solid 1px rgba(0, 0, 0, 0.12)",
        }}
        className="user-info-view"
      >
        <Box
          sx={{
            textAlign: "center",
            width: "100%",
            "& .font-style": {
              backgroundColor: sidebarBgColor,
            },
            ...sxStyle,
          }}
        >
          <IconButton
            onClick={() => history.push(initialUrl)}
            sx={{ p: 0, width: "55px", height: "auto" }}
          >
            <Avatar
              variant="square"
              sx={{
                height: "100%",
                "& .MuiAvatar-img": {
                  width: "100%",
                  padding: "5px",
                  objectFit: "contain",
                },
              }}
              className="font-style"
              src={
                sidebarBgColor === "#fff" || sidebarBgColor === "#f6f8f9"
                  ? "/assets/images/LOGOBLACK.png"
                  : "/assets/images/LOGO1.png"
              }
              style={{ width: "inherit" }}
            />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default UserInfo;
