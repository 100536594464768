import { authRouteConfig } from "./auth";
import { initialUrl } from "shared/constants/AppConst";
import Error403 from "./errorPages/Error403";
import React from "react";
import { dashBoardConfigs } from "./dashboards";
import { DeletedUserConfig, usersConfigs, MarketingUsersConfig } from "./users";
// import { UserDetails } from "./users";
import { settingConfigs } from "./settings";
import { errorLogConfigs } from "./errorlogs";
import { foodrequestsConfigs } from "./foodrequests";
import { errorPagesConfigs } from "./errorPages";
import { extraPagesConfigs } from "./extraPages";
import { cmsConfigs } from "./cms";
import { homeCmsConfigs } from "./cms";
import { emailTempConfigs } from "./emailtemplates";
import { categoryConfigs } from "./category";
import { foodListConfigs } from "./foodList";
import { DeletedNGOConfig, NGOListConfigs } from "./NGO";
import { NGODetails } from "./NGO";
import {
  AdminDonationListConfig,
  PaymentGatewayConfig,
  PaymentModeConfig,
  SaayamTransactionsConfig,
  TaxBenefitsConfig,
} from "./transactions";
import { FeaturedListConfig } from "./transactions";
import { DonationListConfig } from "./transactions";
import { NGODonationConfig } from "./NGO";
import { PlansConfig } from "./planslist";
import { BanksConfig } from "./manage-banks";
// import { GenericCauseDetailConfig } from './generic-cause'
import { ManageReqConfigs } from "./manage-requests";
import { ManageCountryCurrencyConfig } from "./manage-country-currency";
import { CausesDetailConfig } from "./manage-requests";
import { RaceListConfig } from "./Race-Religion";
import { ReligionListConfig } from "./Race-Religion";
import { WomenEmpowermentListConfig } from "./women-empowerment";
import { DeleteAccFormConfig } from "./delete-account";
import { appsConfig } from "./apps";
import {
  RequestsReportsConfig,
  NGOReportsConfig,
  FundreportsConfig,
  HelpRequestsReportsConfig,
  ReportDriveFormConfig,
} from "./report";
import { SchoolsConfig } from "./school-list";
import { HospitalsConfig } from "./hospital-list";
import { EmotionalConfig } from "./emotional-messages";
import { CourseConfig } from "./course-list";
import { DiseaseConfig } from "./disease-list";
import { commonSettingConfigs } from "./common-settings";
import { ImagesListsConfig } from "./images-list";
import { FooterStripConfig } from "./footer-strip";
import { UnverifiedSchoolHospital } from "./unverified-hospital-school";
import { BugReportsConfig } from "./bugreports";
import { CSVFilesConfig } from "./csvfiles";
import { TestimonialsConfig } from "./testimonials";
import { RegionsConfig } from "./regions";
import { FundsListConfigs } from "./funds";
import { LanguagesConfig } from "./languages";
import { BankListsConfig } from "./users-bank-list";
import { HelpRequestsConfig } from "./help-requests";
import { DriveTypesConfig } from "./drive-types";
import { DonationPurposeConfig } from "./donation-purpose";
import { DrivesListConfigs } from "./drive-lists";
import { AdminListsConfig } from "./admin-lists";
import { adminLogsConfigs } from "./adminlogs";
import { ShareMessageConfig } from "./sharemessage";
import { RequestAcceptConfig, RequestRejectConfig } from "./volunteers-form";
import { UserRolesConfig } from "./user-roles";
import { NGOFormConfig } from "./ngo-form";
import {
  CorporateReqAcceptConfig,
  CorporateReqRejectConfig,
} from "./corporate-form";
import { FAQConfig } from "./faq";
import { JobTitleConfig } from "./job-title";
import { CorporateTypesConfig } from "./corporate-types";
import { CorporateCountryFormConfig } from "./corporate-country-form";
import {
  CorporateDetailsConfig,
  CorporateListsConfig,
  DeletedCorporateConfig,
} from "./corporate-lists";
import { DefaultOtpConfig } from "./default-otp";
import { BankTypeConfig } from "./bank-type";
import { SendNotificationConfig } from "./send-notification";
import { DisasterTypesConfig } from "./disaster-types";
import { ManualTransactionConfig } from "./manual-transaction";
import { HumanRightsConfig } from "./human-rights";
import { ElderlyTypesConfig } from "./elderly-types";
import { NTEECategoryConfig } from "./ntee-category";
import { ExemptionTypeConfig } from "./exemption-type";
import { OrganizationSchoolConfig } from "./organization-school";
import { GeneralDynamicFormConfig } from "./general-dynamic-form";
import { VibesListConfig } from "./vibes";
import { UserFormConfig } from "./user-from";
import { TransferFundsListsConfig } from "./transfer-funds-lists";
import { RecurringDonationConfig } from "./recurring-donation";

const authorizedStructure = {
  fallbackPath: "/signin",
  unAuthorizedComponent: <Error403 />,
  routes: [
    ...ElderlyTypesConfig,
    ...SendNotificationConfig,
    ...dashBoardConfigs,
    ...usersConfigs,
    ...DeletedUserConfig,
    ...MarketingUsersConfig,
    // ...UserDetails,
    ...settingConfigs,
    ...commonSettingConfigs,
    ...cmsConfigs,
    ...homeCmsConfigs,
    ...emailTempConfigs,
    ...categoryConfigs,
    ...errorLogConfigs,
    ...foodrequestsConfigs,
    ...foodListConfigs,
    ...NGOListConfigs,
    ...AdminDonationListConfig,
    ...FeaturedListConfig,
    ...SaayamTransactionsConfig,
    ...DonationListConfig,
    ...NGODonationConfig,
    ...PlansConfig,
    ...RequestsReportsConfig,
    ...HelpRequestsReportsConfig,
    ...NGOReportsConfig,
    ...BanksConfig,
    // ...GenericCauseDetailConfig,
    ...ManageCountryCurrencyConfig,
    ...ManageReqConfigs,
    ...CausesDetailConfig,
    ...NGODetails,
    ...CorporateDetailsConfig,
    ...DeletedNGOConfig,
    ...appsConfig,
    ...extraPagesConfigs,
    ...RaceListConfig,
    ...ReligionListConfig,
    ...WomenEmpowermentListConfig,
    ...DeleteAccFormConfig,
    ...SchoolsConfig,
    ...HospitalsConfig,
    ...EmotionalConfig,
    ...CourseConfig,
    ...DiseaseConfig,
    ...PaymentGatewayConfig,
    ...TaxBenefitsConfig,
    ...ImagesListsConfig,
    ...FooterStripConfig,
    ...UnverifiedSchoolHospital,
    ...BugReportsConfig,
    ...CSVFilesConfig,
    ...TestimonialsConfig,
    ...VibesListConfig,
    ...RegionsConfig,
    ...FundsListConfigs,
    ...FundreportsConfig,
    ...ReportDriveFormConfig,
    ...LanguagesConfig,
    ...BankListsConfig,
    ...HelpRequestsConfig,
    ...DriveTypesConfig,
    ...DonationPurposeConfig,
    ...DrivesListConfigs,
    ...AdminListsConfig,
    ...adminLogsConfigs,
    ...ShareMessageConfig,
    ...RequestAcceptConfig,
    ...RequestRejectConfig,
    ...UserRolesConfig,
    ...DisasterTypesConfig,
    ...HumanRightsConfig,
    ...NGOFormConfig,
    ...UserFormConfig,
    ...CorporateReqAcceptConfig,
    ...CorporateReqRejectConfig,
    ...FAQConfig,
    ...JobTitleConfig,
    ...CorporateTypesConfig,
    ...CorporateCountryFormConfig,
    ...CorporateListsConfig,
    ...DeletedCorporateConfig,
    ...DefaultOtpConfig,
    ...BankTypeConfig,
    ...ManualTransactionConfig,
    ...NTEECategoryConfig,
    ...ExemptionTypeConfig,
    ...OrganizationSchoolConfig,
    ...PaymentModeConfig,
    ...GeneralDynamicFormConfig,
    ...TransferFundsListsConfig,
    ...RecurringDonationConfig,
  ],
};

const unAuthorizedStructure = {
  fallbackPath: initialUrl,
  routes: authRouteConfig,
};

const anonymousStructure = {
  routes: errorPagesConfigs,
};

export { authorizedStructure, unAuthorizedStructure, anonymousStructure };
