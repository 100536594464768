import React from "react";
import "./loader.css";

interface LoaderProps {
  sxStyle?: any;
}

const AppLoader: React.FC<LoaderProps> = ({ sxStyle }) => {
  return (
    <div className="app-loader" style={sxStyle}>
      <div className="loader-spin">
        <span className="crema-dot crema-dot-spin">
          <i></i>
          <i></i>
          <i></i>
          <i></i>
          <i></i>
        </span>
      </div>
    </div>
  );
};

export default AppLoader;
