import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { BiUser } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { AppConfirmDialog, AppNotifications } from "@crema";
import { AiOutlineLogout } from "react-icons/ai";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import {
  logoutAction,
  logoutWithoutToken,
  toggleNavCollapsed,
} from "redux/actions";
import { reqFCMToken } from "shared/helpers/firebase";
import AppTooltip from "@crema/core/AppTooltip";
import { AppState } from "redux/store";

const AppHeader = () => {
  const { sidebarBgColor, sidebarTextColor } = useSidebarContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const { userData } = useSelector<AppState, AppState["AuthData"]>(
    ({ AuthData }) => AuthData
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  useEffect(() => {}, [userData]);

  const [logoutLoad, setLogoutLoaing] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);

  const logout = async () => {
    const fcmToken = await reqFCMToken();
    if (fcmToken) {
      const data = { token: fcmToken };
      dispatch(logoutAction(data, setLogoutLoaing));
    } else {
      dispatch(logoutWithoutToken());
    }
  };

  return (
    <AppBar
      position="relative"
      color="inherit"
      sx={{
        boxShadow: "none",
        borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
        backgroundColor: sidebarBgColor,
        width: "100%",
      }}
      className="app-bar"
    >
      <Toolbar
        sx={{
          boxSizing: "border-box",
          minHeight: { xs: 56, sm: 60 },
          paddingLeft: { xs: 5 },
          paddingRight: { xs: 5 },
        }}
      >
        <IconButton
          sx={{ color: sidebarTextColor }}
          edge="start"
          className="menu-btn"
          color="inherit"
          aria-label="open drawer"
          onClick={() => dispatch(toggleNavCollapsed())}
          size="large"
        >
          <MenuIcon sx={{ width: 35, height: 35 }} />
        </IconButton>
        <Box
          sx={{
            "& .logo-text": { display: { xs: "none", sm: "block" } },
            lineHeight: 0,
          }}
        >
          {/* <AppLogo
            sxStyle={{ height: { xs: 40, sm: 40 }, width: { xs: 40, sm: 40 } }}
          /> */}
        </Box>

        <Box sx={{ flexGrow: 1 }} />
        <Box sx={{ px: 1.85 }}>
          <AppNotifications />
        </Box>
        <Box sx={{ ml: 4 }}>
          <AppTooltip title="Profile" placement={"bottom"}>
            <Box onClick={handleClick}>
              <Avatar
                variant="rounded"
                sx={{
                  backgroundColor: sidebarBgColor,
                  cursor: "pointer",
                  border: "1px solid #BDBDBD",
                }}
                src={
                  userData?.image ||
                  "https://avatars.dicebear.com/api/identicon/a.svg?background=%23def6fd"
                }
              />
            </Box>
          </AppTooltip>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            sx={{ py: 4, zIndex: 1000002 }}
          >
            <MenuItem
              sx={{ px: 6, py: 1.5 }}
              onClick={() => {
                handleClose();
                history.push("/my-account");
              }}
            >
              <div
                style={{ fontSize: "20px", padding: "5px 10px", lineHeight: 0 }}
              >
                <BiUser />
              </div>
              My Account
            </MenuItem>
            <MenuItem
              sx={{ px: 6, py: 1.5 }}
              onClick={() => setLogoutModal(true)}
              disabled={logoutLoad}
            >
              <div
                style={{ fontSize: "20px", padding: "5px 10px", lineHeight: 0 }}
              >
                <AiOutlineLogout />
              </div>
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
      <AppConfirmDialog
        open={logoutModal}
        disabled={logoutLoad}
        loading={logoutLoad}
        onDeny={() => {
          setLogoutLoaing(false);
          setLogoutModal(false);
        }}
        title="Are you sure you want to Logout?"
        onConfirm={logout}
        dialogTitle="Logout"
      />
    </AppBar>
  );
};
export default AppHeader;
