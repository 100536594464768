import React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";

const PaginationStyle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  borderTop: "1px solid rgba(224, 224, 224, 1)",
}));

interface AppsPaginationProps {
  count: number;
  page: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    page: number
  ) => void;
  rowsPerPage?: number;

  [x: string]: any;
}

const AppsPagination: React.FC<AppsPaginationProps> = ({
  count,
  page,
  onPageChange,
  rowsPerPage = 20,
  ...rest
}) => {
  return (
    <PaginationStyle>
      <TablePagination
        component="div"
        count={count}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{ "aria-label": "Previous Page" }}
        nextIconButtonProps={{ "aria-label": "Next Page" }}
        onPageChange={onPageChange}
        rowsPerPageOptions={[]}
        {...rest}
      />
    </PaginationStyle>
  );
};

export default AppsPagination;
