import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import AppContentView from "@crema/core/AppContentView";
import {
  useLayoutActionsContext,
  useLayoutContext,
} from "../../utility/AppContextProvider/LayoutContextProvider";
import Layouts from "./Layouts";
import AuthWrapper from "./AuthWrapper";
import { useUrlSearchParams } from "use-url-search-params";
import { useSidebarActionsContext } from "../../utility/AppContextProvider/SidebarContextProvider";
import { useSelector } from "react-redux";
import { AppState } from "../../../redux/store";
import Notification from "components/Notification";

const AppLayout = () => {
  const { navStyle } = useLayoutContext();
  const { idToken } = useSelector<AppState, AppState["AuthData"]>(
    ({ AuthData }) => AuthData
  );
  const { updateNavStyle } = useLayoutActionsContext();
  const { updateMenuStyle, setSidebarBgImage } = useSidebarActionsContext();
  const AppLayout = Layouts[navStyle];
  const [params] = useUrlSearchParams({}, {});

  useEffect(() => {
    if (params.layout) updateNavStyle(params.layout as string);
    if (params.menuStyle) updateMenuStyle(params.menuStyle as string);
    if (params.sidebarImage) setSidebarBgImage(true);
  }, [params]);


  return (
    <>
      {!isEmpty(idToken) ? (
        <>
        <AppLayout />
        {idToken && <Notification sToken={idToken} />}
        </>
      ) : (
        <AuthWrapper>
          <AppContentView />
        </AuthWrapper>
      )}
    </>
  );
};

export default React.memo(AppLayout);
