import React, { useEffect, useState } from "react";
import { Badge, IconButton, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppNotificationContent from "./AppNotificationContent";
import getApiData from "../../../shared/helpers/apiHelper";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AppTooltip from "../AppTooltip";
import { alpha } from "@mui/material/styles";
import { flattenDeep, isEmpty } from "lodash";
import InfiniteList from "react-infinite-scroll-list";
import { SxProps } from "@mui/system";
import { toast } from "react-toastify";
import AppLoader from "../AppLoader";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import { getBadgeCount, getHelpBadgeCount } from "commonFunction";
import { setBadgeCount } from "redux/actions/BadgeCountData";
import { setHelpBadgeCount } from "redux/actions/HelpBadgeCountData";
import Helpnotifications from "@crema/Lotties/Helpnotifications.json";
import Lottie from "lottie-react";

interface AppNotificationsProps {
  drawerPosition?: "left" | "top" | "right" | "bottom";
  tooltipPosition?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  isMenu?: boolean;
  sxNotificationContentStyle?: SxProps<Theme>;
}

const AppNotifications: React.FC<AppNotificationsProps> = ({
  drawerPosition = "right",
  tooltipPosition = "bottom",
  isMenu = false,
  sxNotificationContentStyle = {},
}) => {
  const dispatch = useDispatch();
  const { notiData } = useSelector<AppState, AppState["Notificationdata"]>(
    ({ Notificationdata }) => Notificationdata
  );
  const { badgeCount } = useSelector<AppState, AppState["BadgeCountData"]>(
    ({ BadgeCountData }) => BadgeCountData
  );
  const [btnType, setBtnType] = useState<any>("");

  const { helpBadgeCount } = useSelector<
    AppState,
    AppState["HelpBadgeCountData"]
  >(({ HelpBadgeCountData }) => HelpBadgeCountData);

  const { sidebarTextColor } = useSidebarContext();

  const [showNotification, setShowNotification] = useState(false);

  const [appNotiData, setAppNotiData] = useState<any>({
    lists: [],
    listsLoad: false,
    total: 0,
  });

  const [helpAppNotiData, setHelpAppNotiData] = useState<any>({
    lists: [],
    listsLoad: false,
    total: 0,
  });

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [nextPage, setNextPage] = useState(false);
  const [nextLoading, setNextLoading] = useState(false);

  //API for get help notifications lists
  async function getHelpNotifications(currentPage = page) {
    let data = { page: currentPage };
    setHelpAppNotiData((e) => ({ ...e, listsLoad: true }));
    setLoading(true);
    try {
      const res = await getApiData(
        `notification/admin/list?help_request=1&page=${data.page}`,
        {},
        "GET"
      );
      if (res.success) {
        getHelpBadgeCount(dispatch, setHelpBadgeCount, helpBadgeCount);
        let tempPArr = res.data;
        if (data.page > 1) {
          tempPArr = flattenDeep([helpAppNotiData.lists, res?.data]);
        }
        if (res?.next_enable === 1) {
          setNextPage(true);
        } else {
          setNextPage(false);
        }
        setNextLoading(false);
        setHelpAppNotiData({
          lists: tempPArr || [],
          listsLoad: false,
          total: res.total_count,
        });
      } else {
        setHelpAppNotiData({ lists: [], listsLoad: false, total: 0 });
        toast.error(res.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setHelpAppNotiData({ lists: [], listsLoad: false, total: 0 });
      toast.error("Something went wrong");
    }
  }

  //API for get notifications lists
  async function getNotifications(currentPage = page) {
    let data = { page: currentPage };
    setAppNotiData((e) => ({ ...e, listsLoad: true }));
    setLoading(true);
    try {
      const res = await getApiData(
        `notification/admin/list?page=${data.page}`,
        {},
        "GET"
      );
      if (res.success) {
        getBadgeCount(dispatch, setBadgeCount, badgeCount);
        let tempPArr = res.data;
        if (data.page > 1) {
          tempPArr = flattenDeep([appNotiData.lists, res?.data]);
        }
        if (res?.next_enable === 1) {
          setNextPage(true);
        } else {
          setNextPage(false);
        }
        setNextLoading(false);
        setAppNotiData({
          lists: tempPArr || [],
          listsLoad: false,
          total: res.total_count,
        });
      } else {
        setAppNotiData({ lists: [], listsLoad: false, total: 0 });
        toast.error(res.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setAppNotiData({ lists: [], listsLoad: false, total: 0 });
      toast.error("Something went wrong");
    }
  }

  useEffect(() => {
    if (!isEmpty(notiData)) {
      getNotifications();
      getHelpNotifications();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notiData]);

  const onEndReached = () => {
    const tempPage = page + 1;
    if (nextPage) {
      setNextLoading(true);
      setPage(tempPage);
      if (btnType === "helpnoti") {
        getHelpNotifications(tempPage);
      } else {
        getNotifications(tempPage);
      }
    }
  };

  return (
    <>
      {isMenu ? (
        <Box component="span" onClick={() => setShowNotification(true)}>
          Message
        </Box>
      ) : (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <AppTooltip title="Help Notification" placement={tooltipPosition}>
            <IconButton
              className="icon-btn"
              sx={{
                mr: 5,
                borderRadius: "50%",
                width: 40,
                height: 40,
                color: sidebarTextColor,
                "&:hover, &:focus": {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.9),
                  borderColor: (theme) =>
                    alpha(theme.palette.text.secondary, 0.25),
                },
              }}
              onClick={() => {
                setBtnType("helpnoti");
                getHelpNotifications();
                setShowNotification(true);
              }}
              size="large"
            >
              <Badge
                badgeContent={helpBadgeCount}
                color="warning"
                max={999}
                showZero={false}
                sx={{
                  "& .MuiBadge-badge": {
                    top: helpBadgeCount > 0 ? "10px" : 0,
                    right: helpBadgeCount > 0 ? "10px" : 0,
                  },
                }}
              >
                <Lottie
                  loop={true}
                  autoPlay={true}
                  animationData={Helpnotifications}
                  style={{ width: 50, height: 50 }}
                />
              </Badge>
            </IconButton>
          </AppTooltip>
          <AppTooltip title="Notification" placement={tooltipPosition}>
            <IconButton
              className="icon-btn"
              sx={{
                borderRadius: "50%",
                width: 40,
                height: 40,
                color: sidebarTextColor,
                "&:hover, &:focus": {
                  color: (theme) => theme.palette.text.primary,
                  backgroundColor: (theme) =>
                    alpha(theme.palette.background.default, 0.9),
                  borderColor: (theme) =>
                    alpha(theme.palette.text.secondary, 0.25),
                },
              }}
              onClick={() => {
                setBtnType("noti");
                getNotifications();
                setShowNotification(true);
              }}
              size="large"
            >
              <Badge
                badgeContent={badgeCount}
                color="warning"
                max={999}
                showZero={false}
              >
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
          </AppTooltip>
        </Box>
      )}

      <Drawer
        anchor={drawerPosition}
        open={showNotification}
        onClose={() => {
          setPage(1);
          setBtnType("");
          setShowNotification(false);
        }}
        sx={{ zIndex: 1000002 }}
      >
        <InfiniteList
          root="container|viewport"
          isLoading={nextLoading}
          isEndReached={!nextPage}
          onReachThreshold={() => onEndReached()}
          containerClassName="custom-container-class-name"
          sentinelClassName="custom-sentinel-class-name"
          containerTagName="div"
          sentinelTagName="div"
          threshold={0.8}
          loader={() => {
            if (nextLoading) {
              return <AppLoader />;
            }
          }}
        >
          <AppNotificationContent
            getNotifications={() => {
              if (btnType === "helpnoti") {
                getHelpNotifications();
              } else {
                getNotifications();
              }
            }}
            btnType={btnType}
            isEndReached={!nextPage}
            loading={loading}
            total={
              btnType === "helpnoti"
                ? helpAppNotiData?.total
                : appNotiData?.total
            }
            notificationData={
              btnType === "helpnoti"
                ? helpAppNotiData?.lists
                : appNotiData?.lists
            }
            sxStyle={sxNotificationContentStyle}
            onClose={() => {
              setPage(1);
              setShowNotification(false);
            }}
          />
        </InfiniteList>
      </Drawer>
    </>
  );
};

export default AppNotifications;
