import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const foodListConfigs = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/foodList",
    component: React.lazy(() => import("./foodList")),
  },
];
