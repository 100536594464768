import { MessageType } from "./connectionList";
import { MessageObj } from "../../../../../types/models/apps/Chat";

const chatList: MessageObj[] = [
  {
    channelId: 2001,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:00 PM",
      },
      {
        id: 2,
        sender: 2,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:02 PM",
      },
      {
        id: 3,
        sender: 1,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:03 PM",
      },
      {
        id: 4,
        sender: 2,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:05 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "all well here too, please check your mail!",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:13 PM",
      },
      {
        id: 6,
        sender: 2,
        message: "ok, will do it",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 2,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 8,
        sender: 1,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 9,
        sender: 1,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/docs.txt",
            mime_type: "docs",
            file_name: "docs.txt",
            file_size: 12332,
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 10,
        sender: 2,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/docs.txt",
            mime_type: "docs",
            file_name: "docs.txt",
            file_size: 1232232,
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
    ],
  },
  {
    channelId: 2002,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:00 PM",
      },
      {
        id: 2,
        sender: 2,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:02 PM",
      },
      {
        id: 3,
        sender: 3,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:03 PM",
      },
      {
        id: 4,
        sender: 2,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:05 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "all well here too, please check your mail!",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:13 PM",
      },
      {
        id: 6,
        sender: 3,
        message: "ok, will do it",
        message_type: MessageType.TEXT,
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 2,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 8,
        sender: 1,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 9,
        sender: 1,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/docs.txt",
            mime_type: "docs",
            file_name: "docs.txt",
            file_size: 12332,
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
      {
        id: 10,
        sender: 2,
        message: "ok, will do it",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/docs.txt",
            mime_type: "docs",
            file_name: "docs.txt",
            file_size: 1232232,
          },
        ],
        time: "Sat, May 09, 2020 8:30 PM",
      },
    ],
  },
  {
    channelId: 2004,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 7:30 PM",
      },
      {
        id: 2,
        sender: 5,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 8:00 PM",
      },
      {
        id: 3,
        sender: 5,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 8:03 PM",
      },
      {
        id: 4,
        sender: 1,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 8:15 PM",
      },
      {
        id: 5,
        sender: 5,
        message: "all well here too",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 8:18 PM",
      },
      {
        id: 6,
        sender: 1,
        message: "cheers!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 1,
        message: "!",
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/video.mp4",
            mime_type: "video",
            file_name: "video.mp4",
          },
          {
            id: 2,
            url: "/assets/images/video.mp4",
            mime_type: "video",
            file_name: "video.mp4",
          },
          {
            id: 3,
            url: "/assets/images/video.mp4",
            mime_type: "image",
            file_name: "video.mp4",
          },
        ],
        time: "Fri, May 08, 2020 8:30 PM",
      },
    ],
  },
  {
    channelId: 2006,
    messageData: [
      {
        id: 1,
        sender: 7,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:10 PM",
      },
      {
        id: 2,
        sender: 1,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:13 PM",
      },
      {
        id: 3,
        sender: 7,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:17 PM",
      },
      {
        id: 4,
        sender: 7,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:24 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "all well here too",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:26 PM",
      },
      {
        id: 6,
        sender: 7,
        message: "what are you upto these days?",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 5:30 PM",
      },
      {
        id: 7,
        sender: 7,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
          {
            id: 2,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
          {
            id: 3,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
  {
    channelId: 2009,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Thru, May 07, 2020 3:30 PM",
      },
      {
        id: 2,
        sender: 10,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 10:30 AM",
      },
      {
        id: 3,
        sender: 1,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 11:32 AM",
      },
      {
        id: 4,
        sender: 10,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 2:30 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "Sorry a bit busy, will talk to you later!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 3:24 PM",
      },
      {
        id: 6,
        sender: 10,
        message: "No worries!",
        message_type: MessageType.TEXT,
        time: "Fri, May 08, 2020 4:30 PM",
      },
      {
        id: 7,
        sender: 10,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
  {
    channelId: 2010,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "We got the assignment",
        message_type: MessageType.TEXT,
        time: "Thru, May 07, 2020 2:08 PM",
      },
      {
        id: 2,
        sender: 11,
        message: "congratulations!",
        message_type: MessageType.TEXT,
        time: "Thru, May 07, 2020 2:20 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "We all worked hard, see you in the party!",
        message_type: MessageType.TEXT,
        time: "Thru, May 07, 2020 2:24 PM",
      },
      {
        id: 6,
        sender: 11,
        message: "cheers!",
        message_type: MessageType.TEXT,
        time: "Thru, May 07, 2020 2:30 PM",
      },
      {
        id: 7,
        sender: 1,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
  {
    channelId: 2016,
    messageData: [
      {
        id: 1,
        sender: 17,
        message: "Did you watch the news?",
        message_type: MessageType.TEXT,
        time: "Tues, May 05, 2020 8:10 PM",
      },
      {
        id: 4,
        sender: 1,
        message: "No, anything special today?",
        message_type: MessageType.TEXT,
        time: "Tues, May 05, 2020 8:12 PM",
      },
      {
        id: 5,
        sender: 17,
        message: "Yes, our organization got nominated!",
        message_type: MessageType.TEXT,
        time: "Tues, May 05, 2020 8:16 PM",
      },
      {
        id: 6,
        sender: 1,
        message: "for what?",
        message_type: MessageType.TEXT,
        time: "Tues, May 05, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 1,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
  {
    channelId: 2017,
    messageData: [
      {
        id: 1,
        sender: 18,
        message: "Hello",
        message_type: MessageType.TEXT,
        time: "Sun, May 02, 2020 6:30 PM",
      },
      {
        id: 6,
        sender: 1,
        message: "Hi!",
        message_type: MessageType.TEXT,
        time: "Sun, May 03, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 7,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
          {
            id: 2,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
          {
            id: 3,
            url: "/assets/images/illustration.png",
            mime_type: "image",
            file_name: "illustration.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
  {
    channelId: 2020,
    messageData: [
      {
        id: 1,
        sender: 1,
        message: "Hey Robert",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:11 PM",
      },
      {
        id: 2,
        sender: 21,
        message: "Hi",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:14 PM",
      },
      {
        id: 3,
        sender: 1,
        message: "how you doing?",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:19 PM",
      },
      {
        id: 4,
        sender: 21,
        message: "all well, you say!",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:21 PM",
      },
      {
        id: 5,
        sender: 1,
        message: "all well here too, where r u these days?",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:24 PM",
      },
      {
        id: 6,
        sender: 21,
        message: "in India",
        message_type: MessageType.TEXT,
        time: "Fri, May 01, 2020 8:30 PM",
      },
      {
        id: 7,
        sender: 21,
        message_type: MessageType.MEDIA,
        media: [
          {
            id: 1,
            url: "/assets/images/bitcoin3.png",
            mime_type: "image",
            file_name: "bitcoin3.png",
          },
          {
            id: 2,
            url: "/assets/images/bitcoin1.png",
            mime_type: "image",
            file_name: "bitcoin1.png",
          },
          {
            id: 3,
            url: "/assets/images/bitcoin2.png",
            mime_type: "image",
            file_name: "bitcoin2.png",
          },
        ],
        time: "Fri, May 08, 2020 5:30 PM",
      },
    ],
  },
];

export default chatList;
