import { FormItemTypes, SET_FORM_ITEM } from "types/actions/FormItem.actions";

export interface FormItemData {
  formItem: [];
}

const initState: FormItemData = { formItem: [] };

export default function authReducer(state = initState, action: FormItemTypes) {
  switch (action.type) {
    case SET_FORM_ITEM:
      return {
        ...state,
        formItem: action.formItem,
      };
    default:
      return state;
  }
}
