import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const HelpRequestsReportsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/help-requests-report-form",
    component: React.lazy(() => import("./report-form")),
  },
];

export const RequestsReportsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/requests-report-form",
    component: React.lazy(() => import("./report-form")),
  },
];

export const NGOReportsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/NGO-report-form",
    component: React.lazy(() => import("./report-form")),
  },
];

export const FundreportsConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/fund-report-form",
    component: React.lazy(() => import("./report-form")),
  },
];

export const ReportDriveFormConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/report-drive",
    component: React.lazy(() => import("../delete-account/form-list")),
  },
];
