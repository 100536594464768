import React from "react";
import { Box } from "@mui/material";
import { SxProps } from "@mui/system";

interface AppLogoProps {
  color?: string;
  sxStyle?: SxProps;
}

const AppLogo: React.FC<AppLogoProps> = ({ color, sxStyle }) => {
  return (
    <Box
      sx={{
        "& svg": { height: { xs: 40, sm: 45 } },
        ".logo": { lineHeight: 0 },
        ...sxStyle,
      }}
      className="app-logo"
    >
      <img src="/assets/images/logo.png" className="logo" alt="logo" />
    </Box>
  );
};

export default AppLogo;
