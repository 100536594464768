import React, { useState } from "react";
import AppSidebar from "./AppSidebar";
// import { AppContentView } from "../../../index";
import AppThemeSetting from "../../AppThemeSetting";
// import AppHeader from "./AppHeader";
import clsx from "clsx";
import Hidden from "@mui/material/Hidden";
import BitBucketWrapper from "./BitBucketWrapper";
import { LayoutType } from "../../../../shared/constants/AppEnums";
import { useLayoutContext } from "../../../utility/AppContextProvider/LayoutContextProvider";
import BitBucketContainer from "./BitBucketContainer";
import AppHeader from "../DefaultLayout/AppHeader";
import AppFixedFooter from "../DefaultLayout/AppFixedFooter";
import MainContent from "../DefaultLayout/MainContent";
import AppContentView from "@crema/core/AppContentView";

const BitBucket = () => {
  const [isCollapsed, setCollapsed] = useState(false);
  const { footer, layoutType, headerType, footerType } = useLayoutContext();

  return (
    <BitBucketContainer
      className={clsx({
        boxedLayout: layoutType === LayoutType.BOXED,
        framedLayout: layoutType === LayoutType.FRAMED,
      })}
    >
      <BitBucketWrapper
        className={clsx("bitBucketWrapper", {
          bitBucketCollapsed: isCollapsed,
          appMainFooter: footer && footerType === "fluid",
          appMainFixedFooter: footer && footerType === "fixed",
          appMainFixedHeader: headerType === "fixed",
        })}
      >
        <Hidden lgUp>
          <AppHeader />
        </Hidden>
        <AppSidebar isCollapsed={isCollapsed} setCollapsed={setCollapsed} />
        <MainContent>
          <AppHeader isCollapsed={isCollapsed} />
          <AppContentView sxStyle={{ mt: { md: "0px", lg: "65px" } }} />
          <AppFixedFooter />
        </MainContent>
        <AppThemeSetting />
      </BitBucketWrapper>
    </BitBucketContainer>
  );
};

export default BitBucket;
