import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Box,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  CircularProgress,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { BiUser } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { AppConfirmDialog, AppNotifications } from "@crema";
import { useSidebarContext } from "@crema/utility/AppContextProvider/SidebarContextProvider";
import AppLogo from "../../components/AppLogo";
import HeaderNavWrapper from "./HeaderNavWrapper";
import {
  logoutAction,
  logoutWithoutToken,
  toggleNavCollapsed,
} from "redux/actions";
import HorizontalNav from "../../components/HorizontalNav";
import { reqFCMToken } from "shared/helpers/firebase";
import AppTooltip from "@crema/core/AppTooltip";
import { AppState } from "redux/store";

const AppHeader = () => {
  const { sidebarBgColor, sidebarTextColor } = useSidebarContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const history = useHistory();
  const { userData } = useSelector<AppState, AppState["AuthData"]>(
    ({ AuthData }) => AuthData
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dispatch = useDispatch();

  const [logoutLoad, setLogoutLoaing] = useState<boolean>(false);
  const [logoutModal, setLogoutModal] = useState<boolean>(false);

  useEffect(() => {}, [userData]);

  const logout = async () => {
    const fcmToken = await reqFCMToken();
    if (fcmToken) {
      const data = { token: fcmToken };
      dispatch(logoutAction(data, setLogoutLoaing));
    } else {
      dispatch(logoutWithoutToken());
    }
  };

  return (
    <>
      <AppBar
        position="relative"
        color="inherit"
        sx={{
          boxShadow: "none",
          borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          backgroundColor: sidebarBgColor,
          width: { xs: "100%" },
        }}
        className="app-bar"
      >
        {/* <NotificationBar /> */}
        <Toolbar
          sx={{
            boxSizing: "border-box",
            minHeight: { xs: 56, sm: 60 },
            px: { xs: 0 },
            borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: { lg: 1140, xl: 1420 },
              mx: "auto",
              px: 5,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Hidden lgUp>
              <IconButton
                sx={{
                  marginRight: (theme) => theme.spacing(2),
                  color: sidebarTextColor,
                }}
                edge="start"
                className="menu-btn"
                color="inherit"
                aria-label="open drawer"
                onClick={() => dispatch(toggleNavCollapsed())}
                size="large"
              >
                <MenuIcon sx={{ width: 35, height: 35 }} />
              </IconButton>
            </Hidden>

            <Box
              sx={{
                "& .app-logo": { pl: 0 },
                "& .logo-text": { display: { xs: "none", sm: "block" } },
              }}
            >
              <AppLogo
                sxStyle={{
                  height: { xs: 40, sm: 40 },
                  width: { xs: 40, sm: 40 },
                }}
              />
            </Box>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ px: 1.85 }}>
              <AppNotifications />
            </Box>
            <AppTooltip title="Profile" placement={"bottom"}>
              <Box sx={{ ml: 4 }}>
                <Box onClick={handleClick}>
                  <Avatar
                    variant="rounded"
                    sx={{
                      backgroundColor: sidebarBgColor,
                      cursor: "pointer",
                      border: "1px solid #BDBDBD",
                    }}
                    src={
                      userData?.image ||
                      "https://avatars.dicebear.com/api/identicon/a.svg?background=%23def6fd"
                    }
                  />
                </Box>
              </Box>
            </AppTooltip>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              sx={{ py: 4, zIndex: 1000002 }}
            >
              <MenuItem
                sx={{ px: 6, py: 1.5 }}
                onClick={() => {
                  handleClose();
                  history.push("/my-account");
                }}
              >
                <div
                  style={{
                    fontSize: "20px",
                    padding: "5px 10px",
                    lineHeight: 0,
                  }}
                >
                  <BiUser />
                </div>
                My Account
              </MenuItem>
              <MenuItem
                sx={{ px: 6, py: 1.5 }}
                onClick={() => setLogoutModal(true)}
                disabled={logoutLoad}
              >
                {logoutLoad ? (
                  <div style={{ padding: "5px 10px", lineHeight: 0 }}>
                    <CircularProgress size={20} />
                  </div>
                ) : (
                  <div
                    style={{
                      fontSize: "20px",
                      padding: "5px 10px",
                      lineHeight: 0,
                    }}
                  >
                    <AiOutlineLogout />
                  </div>
                )}
                Logout
              </MenuItem>
            </Menu>

            {/* <Box
              sx={{
                ml: { sm: 4 },
                mr: { xs: 4, sm: 0 },
                minWidth: { md: 220 },
                "& .user-info-view": {
                  p: 0,
                },
                "& .user-info": {
                  display: { xs: "none", md: "block" },
                },
              }}
            >
              <UserInfo sxStyle={{ height: 50 }} />
            </Box> */}
          </Box>
        </Toolbar>
        <Hidden lgDown>
          <HeaderNavWrapper>
            <Box
              sx={{
                width: "100%",
                maxWidth: { lg: 1140, xl: 1436 },
                mx: "auto",
                px: 5,
              }}
            >
              <HorizontalNav />
            </Box>
          </HeaderNavWrapper>
        </Hidden>
        <AppConfirmDialog
          open={logoutModal}
          disabled={logoutLoad}
          loading={logoutLoad}
          onDeny={() => {
            setLogoutLoaing(false);
            setLogoutModal(false);
          }}
          title="Are you sure you want to Logout?"
          onConfirm={logout}
          dialogTitle="Logout"
        />
      </AppBar>
    </>
  );
};
export default AppHeader;
