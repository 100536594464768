import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppSuspense } from "../../index";
import {
  anonymousStructure,
  authorizedStructure,
  unAuthorizedStructure,
} from "../../../pages";
import AppFooter from "../AppLayout/components/AppFooter";
import AppErrorBoundary from "../AppErrorBoundary";
import { isEmpty } from "lodash";
import generateRoutes from "../../utility/RouteGenerator";
import { useAuthUser } from "../../utility/AuthHooks";
import Error404 from "../../../pages/errorPages/Error404";
import Box from "@mui/material/Box";
import { AppState } from "../../../redux/store";
import AppContentViewWrapper from "./AppContentViewWrapper";
import { SxProps } from "@mui/system";

interface AppContentViewProps {
  sxStyle?: SxProps;
}

const AppContentView: React.FC<AppContentViewProps> = ({ sxStyle }) => {
  const { user } = useAuthUser();
  const { idToken } = useSelector<AppState, AppState["AuthData"]>(
    ({ AuthData }) => AuthData
  );
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvment
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  return (
    <AppContentViewWrapper>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          ...sxStyle,
          // padding: "10px",
        }}
        className="app-content"
      >
        <AppSuspense>
          <AppErrorBoundary>
            <Switch>
              {generateRoutes({
                isAuthenticated: !isEmpty(idToken) ? true : false,
                userRole: user?.role,
                unAuthorizedStructure,
                authorizedStructure,
                anonymousStructure,
              })}
              <Route path="/">
                <Error404 />
              </Route>
            </Switch>
            {/* <Switch>
              {isEmpty(idToken) && (
                <Route path="/">
                  <SignIn />
                </Route>
              )}
            </Switch> */}
          </AppErrorBoundary>
        </AppSuspense>
      </Box>
      <AppFooter />
    </AppContentViewWrapper>
  );
};

export default AppContentView;
