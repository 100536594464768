import React from "react";
import BucketMinibarWrapper from "./BucketMinibarWrapper";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import UserInfo from "../UserInfo";
import AppLogo from "../../../components/AppLogo";

const BucketMinibar = () => {
  return (
    <BucketMinibarWrapper>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 1.5,
        }}
      >
        {/* <IconButton
          sx={{
            flexDirection: "column",
            color: "white",
            mb: 2.5,
          }}
          aria-label="show 17 new notifications"
        > */}
        <Box
          sx={{
            height: { xs: 40, sm: 40 },
            width: { xs: 40, sm: 40 },
            ml: 2.2,
            mt: 2.2,
          }}
        ></Box>
        {/* <AppLogo
          sxStyle={{
            height: { xs: 40, sm: 40 },
            width: { xs: 40, sm: 40 },
            ml: 2.2,
            mt: 2.2,
          }}
        /> */}
        {/* </IconButton> */}
      </Box>
      {/* <Box sx={{ mt: "auto" }}>
        <UserInfo />
      </Box> */}
    </BucketMinibarWrapper>
  );
};

export default BucketMinibar;
