import React from "react";
import { CircularProgress, Popover, Theme } from "@mui/material";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import IntlMessages from "@crema/utility/IntlMessages";
import NotificationItem from "./NotificationItem";
import { setBadgeCount } from "redux/actions/BadgeCountData";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/system";
import { Fonts } from "shared/constants/AppEnums";
import { Delete } from "@mui/icons-material";
import getApiData from "../../../shared/helpers/apiHelper";
import { toast } from "react-toastify";
import confirm from "@crema/Lotties/confirm.json";
import Lottie from "lottie-react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "redux/store";
import { LoadingButton } from "@mui/lab";
import ChatListSkeleton from "../AppSkeleton/ChatListSkeleton";
import { setHelpBadgeCount } from "redux/actions/HelpBadgeCountData";

interface AppNotificationContentProps {
  onClose: () => void;
  notificationData: any;
  sxStyle: SxProps<Theme>;
  total: number;
  loading: boolean;
  isEndReached: boolean;
  getNotifications: (data) => void;
  btnType?: any;
}

const AppNotificationContent: React.FC<AppNotificationContentProps> = ({
  onClose,
  sxStyle,
  notificationData,
  total,
  loading,
  isEndReached,
  getNotifications,
  btnType,
}) => {
  const { badgeCount } = useSelector<AppState, AppState["BadgeCountData"]>(
    ({ BadgeCountData }) => BadgeCountData
  );
  const { helpBadgeCount } = useSelector<
    AppState,
    AppState["HelpBadgeCountData"]
  >(({ HelpBadgeCountData }) => HelpBadgeCountData);

  const dispatch = useDispatch();

  let myDiv: any = document.getElementById("scrollTo");
  const scrollToTop = () => {
    myDiv.scrollIntoView({ top: 0, behavior: "smooth" });
  };
  const [openDltModal, setOpenDltModal] = React.useState<any>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [btnLoad, setBtnLoad] = React.useState<any>(false);
  const [dltLoad, setDltLoad] = React.useState<any>(false);
  const [type, setType] = React.useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    event.stopPropagation();
    event.preventDefault();
  };

  //API For delete all notifications
  async function deleteNotification() {
    setDltLoad(true);
    try {
      let url = "";
      if (btnType === "helpnoti") {
        url = `notification/admin/remove-all?help_request=1`;
      } else {
        url = `notification/admin/remove-all`;
      }
      const res = await getApiData(url, {}, "DELETE");
      if (res.success) {
        await getNotifications({ page: 1 });
        setOpenDltModal(false);
        dispatch(setBadgeCount(0));
        setAnchorEl(null);
        toast.success(res.message);
      } else {
        toast.error(res.message);
      }
      setDltLoad(false);
    } catch (error) {
      setDltLoad(false);
      console.log("delete error", error);
      toast.error("Something went wrong");
    }
  }

  //API for read all notifications
  async function readAllNotification() {
    setDltLoad(true);
    try {
      let url = "";
      if (btnType === "helpnoti") {
        url = `notification/admin/read-all?help_request=1`;
      } else {
        url = `notification/admin/read-all`;
      }
      const res = await getApiData(url, {}, "GET");
      if (res.success) {
        await getNotifications({ page: 1 });
        setOpenDltModal(false);
        if (btnType === "helpnoti") {
          dispatch(setHelpBadgeCount(0));
        } else {
          dispatch(setBadgeCount(0));
        }
        toast.error(res.message);
        setType("");
      } else {
        if (btnType === "helpnoti") {
          dispatch(setHelpBadgeCount(helpBadgeCount));
        } else {
          dispatch(setBadgeCount(badgeCount));
        }
        toast.error(res.message);
        setDltLoad(false);
      }
      setDltLoad(false);
    } catch (error) {
      toast.error("Something went wrong");
      setDltLoad(false);
      console.log("🚀 ~ readAllNotification ~ error", error);
    }
  }

  return (
    <>
      <Box
        sx={{
          padding: "5px 15px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection:
            btnType === "helpnoti"
              ? helpBadgeCount > 0
                ? "column"
                : "row"
              : badgeCount > 0
              ? "column"
              : "row",
          borderBottom: 1,
          borderBottomColor: (theme) => theme.palette.divider,
          minHeight: { xs: 56, sm: 70 },
          position: "sticky",
          top: 0,
          width: 350,
          background: "white",
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Typography component="h3">
            <IntlMessages
              id={
                btnType === "helpnoti"
                  ? "Help Notifications"
                  : "common.notifications"
              }
            />
            ({total})
          </Typography>
          <Box>
            {total ? (
              <Button
                sx={{ padding: "3px 9px 0px" }}
                size="small"
                color="warning"
                variant="outlined"
                startIcon={<Delete sx={{ mb: "3px" }} />}
                onClick={(event) => {
                  handleClick(event);
                  setOpenDltModal(true);
                }}
              >
                All
              </Button>
            ) : null}
          </Box>
        </Box>
        {btnType === "helpnoti" ? (
          helpBadgeCount > 0 ? (
            <Box
              sx={{
                justifyContent: "flex-end",
                display: "flex",
                width: "100%",
              }}
            >
              <LoadingButton
                size="small"
                variant="text"
                disabled={btnLoad}
                loading={btnLoad}
                onClick={(event) => {
                  handleClick(event);
                  setOpenDltModal(true);
                  setType("markRead");
                }}
                sx={{
                  color: "#6B7280",
                  fontSize: 12,
                  textDecoration: "underline",
                  p: 0,
                }}
              >
                Mark all as read
              </LoadingButton>
            </Box>
          ) : null
        ) : badgeCount > 0 ? (
          <Box
            sx={{ justifyContent: "flex-end", display: "flex", width: "100%" }}
          >
            <LoadingButton
              size="small"
              variant="text"
              disabled={btnLoad}
              loading={btnLoad}
              onClick={(event) => {
                handleClick(event);
                setOpenDltModal(true);
                setType("markRead");
              }}
              sx={{
                color: "#6B7280",
                fontSize: 12,
                textDecoration: "underline",
                p: 0,
              }}
            >
              Mark all as read
            </LoadingButton>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: 350,
          ...sxStyle,
        }}
      >
        {!loading && !total ? (
          <Box
            sx={{
              height: "calc(100vh - 100px)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: Fonts.MEDIUM }}>
              No Notifications
            </Typography>
          </Box>
        ) : (
          <>
            <Box
              id="scrollTo"
              sx={{
                height: { xs: "calc(100% - 96px)", sm: "calc(100% - 110px)" },
              }}
            >
              {loading && isEndReached ? (
                <ChatListSkeleton />
              ) : (
                <List sx={{ py: 0 }}>
                  {notificationData &&
                    notificationData.length > 0 &&
                    notificationData.map((item) => (
                      <NotificationItem
                        btnType={btnType}
                        key={item.id}
                        item={item}
                        onClose={onClose}
                        getNotifications={getNotifications}
                      />
                    ))}
                </List>
              )}
            </Box>

            {!isEndReached ? (
              <ChatListSkeleton />
            ) : isEndReached && !loading ? (
              <Button
                sx={{
                  borderRadius: 0,
                  width: "100%",
                  textTransform: "capitalize",
                  marginTop: "auto",
                  height: "100%",
                }}
                color="success"
                disabled={loading}
                variant="text"
                onClick={scrollToTop}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    mb: 5,
                    width: "100%",
                  }}
                >
                  <Lottie
                    loop={false}
                    autoPlay={true}
                    animationData={confirm}
                    style={{ width: 80, height: 80 }}
                  />
                  <Typography>you have seen it all</Typography>
                </Box>
              </Button>
            ) : null}
          </>
        )}
        <Popover
          open={openDltModal}
          anchorEl={anchorEl}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          sx={{ zIndex: 1000002 }}
        >
          <Box style={{ padding: "20px" }}>
            <Typography variant="h6" component="h2">
              {type === "markRead"
                ? "Are you sure you want to mark all as Read?"
                : "Are you sure you want to delete all notification?"}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "20px",
                width: "100%",
              }}
            >
              <Button
                disabled={dltLoad}
                size="small"
                variant="outlined"
                onClick={() => {
                  if (type === "markRead") {
                    readAllNotification();
                  } else {
                    deleteNotification();
                  }
                }}
              >
                {dltLoad ? (
                  <CircularProgress
                    size={14}
                    color="primary"
                    style={{ margin: "5px 0px" }}
                  />
                ) : (
                  "Yes"
                )}
              </Button>
              <Button
                size="small"
                variant="outlined"
                color="warning"
                onClick={() => {
                  setType("");
                  setAnchorEl(null);
                  setDltLoad(false);
                  setOpenDltModal(false);
                }}
                autoFocus
              >
                Cancel
              </Button>
            </div>
          </Box>
        </Popover>
      </Box>
    </>
  );
};

export default AppNotificationContent;
