import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const GeneralDynamicFormConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/general-dynamic-form",
    component: React.lazy(() => import("./general-dynamic-form")),
  },
];
