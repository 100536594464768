import React from "react";
import { RoutePermittedRole } from "shared/constants/AppConst";

export const FAQConfig = [
  {
    permittedRole: RoutePermittedRole.User,
    path: "/faq",
    component: React.lazy(() => import("./faq")),
  },
];
