export const NGOForm: any = [
  {
    title: "NGO Form",
    is_accordian: true,
    help_title: "NGO Form",
    inputs: [
      {
        title: "NGO Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "ngo_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 30,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "4bee9da6-fdc2-4db0-8086-22e530efd031",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "First Name(Trustee)",
        other_inputs: [],
        input_type: "string",
        input_slug: "first_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "369d2393-3fc3-47ba-b8e4-4cb1d7a354df",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Last Name(Trustee)",
        other_inputs: [],
        input_type: "string",
        input_slug: "last_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "40e6145e-e40f-4e4e-8ed2-a85bb3986fee",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Enter your NGO Address",
        other_inputs: [],
        input_type: "location",
        input_slug: "ngo_address",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a3f83e40-90d9-419e-8deb-b434b7752873",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Registration Certificate Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "registration_certificate_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "72976c73-f48d-40bd-bd09-3962ae79e7a4",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "NGO Mobile Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "ngo_mobile_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 10,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "14ea0049-f41f-483d-9f82-b32b01fe2bbd",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Secondary Mobile Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "secondary_mobile_number",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "627999ca-81fa-44c7-9277-6b995630cd67",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "NGO Website Link",
        other_inputs: [],
        input_type: "string",
        input_slug: "website_link",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c68c1643-3570-4757-9248-431a496c0da2",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "NGO Email",
        other_inputs: [],
        input_type: "email",
        input_slug: "ngo_email",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 5,
        max: 30,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7bce380f-3819-4dac-977c-ebe982fb57ee",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Expiry Date of NGO",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9b4edb7b-68d7-4454-a418-1fe7a17a307f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "About your NGO",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "about_your_ngo",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 20,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "bab1bf0e-089b-4ab1-8461-8fcf30cf0e80",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Do you have 12A or 80G Certificates?",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "12a_or_80g_certificates",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Do you have 12A or 80G Certificates?"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "8a30e03d-5a8c-4144-a4f6-f313f8393820",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "12A Registration Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "12a_registration_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "12a_or_80g_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "58aa9bb4-0373-4b2e-b659-e08bffd49b40",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Upload 12A Registration Document",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_12a_registration_document",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "12a_or_80g_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 10,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "f5bf93c0-fdbc-46b9-aeab-e1bdad121a8a",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "80G Registration Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "80g_registration_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "12a_or_80g_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d109b412-385e-4d27-92bf-cf47dd46d518",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Upload 80G Registration Document",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_80g_registration_document",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "12a_or_80g_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 10,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cf3aa1d4-f862-4ace-a9db-12796c030327",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Do you have FCRA Certificates?",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "fcra_certificates",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Do you have FCRA Certificates?"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "66924f5b-b644-4611-8902-66846c180780",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "FCRA Registration Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "fcra_registration_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "fcra_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "dd55e969-af82-4081-910e-dc30e9d24fea",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Upload FCRA Registration Document",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_fcra_registration_document",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "fcra_certificates",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 10,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "fbb1dbde-3067-4e2b-bc67-48b5a4c5c041",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
    ],
    id: "66aa69b5-dfa2-41b3-af17-af4d2511c08a",
    visible: [],
    haveError: false,
  },
];

export const HungerNew: any = [
  {
    title: "Hunger",
    help_title: "Hunger",
    inputs: [
      {
        title: "How many hungry people?",
        other_inputs: [
          {
            other_title: "How many hungry people?",
            other_input_type: "number",
            other_min: 1,
            other_is_required: true,
            other_max: 1000,
            other_input_slug: "how_many_hungry_people",
          },
        ],
        input_type: "radio",
        input_slug: "how_many_persons",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        is_accordian: true,
        min: 0,
        max: 0,
        options: ["1", "2", "3", "4", "5"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b1c76799-ec6c-4bb4-b2a0-e5d18066937f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Deliver Before (hrs)",
        other_inputs: [
          {
            other_title: "Deliver Before (hrs)",
            other_input_type: "number",
            other_min: 1,
            other_is_required: true,
            other_max: 8,
            other_input_slug: "deliver_before",
          },
        ],
        input_type: "radio",
        input_slug: "deliver_before",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: true,
        min: 0,
        max: 0,
        options: ["1", "2", "3", "4", "5"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ff49357e-77d7-4d6a-a9c3-ac8cb1f8543a",
        prefix: "",
        suffix: "hr",
        key: "",
        haveError: false,
      },
      {
        title: "Food for Myself",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "food_for_myself",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["food_for_myself"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9d53be58-201b-4ed7-9844-ba5d9b663733",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Vegetarian",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "vegetarian",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["veg_only"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "5fb2c90d-7a2e-47e2-95de-2d6b1c37b627",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Delivery Location",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        is_disabled: true,
        is_accordian: true,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "f7a90ed5-b8e1-4e25-915e-12231f2894d1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Near By",
        other_inputs: [],
        input_type: "string",
        input_slug: "near_by",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "5d478406-cee5-415d-9193-80de7ebce229",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Photos of Delivery Location",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6e39e694-6331-4132-9fa1-fca95bfd5441",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "1d4f3909-14bc-4a95-a73a-2ceb6f8e35ed",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 1000,
        video_duration: 30,
        haveError: false,
      },
    ],
    is_accordian: true,
    id: "81aa9542-77a3-4e07-856c-06f9a9ffb7e7",
    visible: ["user", "donor", "volunteer", "ngo"],
    haveError: false,
  },
];

export const FundraiserNew: any = [
  {
    title: "Outer Section",
    is_accordian: false,
    help_title: "Outer Section",
    inputs: [
      {
        title: "Urgent Help",
        other_inputs: [
          {
            other_title: "Description",
            other_input_type: "textarea",
            other_min: 10,
            other_is_required: true,
            other_max: 200,
            other_input_slug: "description",
          },
        ],
        input_type: "checkbox",
        input_slug: "urgent_help",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        dependant_value: "",
        is_accordian: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c3dffa82-4cfd-47e3-bb4f-bfe095a56399",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "4a4c71ed-3ec4-4298-b273-6bced8fcfdba",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Fundraiser Details",
    help_title: "Help Us To Give Our Baby A New Life, Kindly Donate !",
    inputs: [
      {
        title: "Fundraiser Title",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 3,
        max: 50,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6d150050-d4b3-4ef5-b0e6-7ac56c0e1d27",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Relation With Beneficiary",
        other_inputs: [],
        input_type: "select",
        input_slug: "relation_with_beneficiary",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "Brother",
          "Daughter",
          "Father",
          "Friend",
          "Husband",
          "Mother",
          "Sister",
          "Son",
          "Wife",
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d226b124-7187-4948-a67b-3db7e0049c49",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Name of Beneficiary",
        other_inputs: [],
        input_type: "string",
        input_slug: "name_of_beneficiary",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "04493ddb-5fab-4c14-b7cc-a865565bc64b",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "City (Location)",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ebdb87ce-5f1f-4060-97b5-f2cf20ede6f2",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Need funds by",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b1abfac7-6d7b-4428-aae2-25b8e27f2285",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Goal Amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "f660da38-e13e-4c20-b2a9-f6e5f0c56114",
        prefix: "",
        suffix: "",
        add_suffix: true,
        haveError: false,
      },
      {
        title: "Write Your Story",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "64e97d51-5929-4bdd-b849-f4247fb81c2c",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Upload Cover Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6c7b9cd1-91ed-492d-beac-6ae91550cf93",
        prefix: "",
        suffix: "",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Photos",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cb4e7139-e298-4acd-83dd-2e4cd0b68771",
        prefix: "",
        suffix: "",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_accordian: true,
        is_required: false,
        is_disabled: false,
        other_input: false,
        min: 0,
        max: 1,
        options: [""],
        file_type: "video",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ac89bd4f-0bbc-49d0-a272-d0037e32f5ff",
        prefix: "",
        suffix: "",
        file_size: 1000,
        video_duration: 30,
        haveError: false,
      },
    ],
    id: "489fa5bd-0942-411f-a577-3fa9482dc6ca",
    visible: ["donor", "volunteer", "ngo", "guest", "user"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Documents",
    help_title: "Documents",
    inputs: [
      {
        title: "Proof of Submission",
        other_inputs: [],
        input_type: "file",
        input_slug: "proof_of_income",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6233ba3e-7e18-4134-8a09-ae02331ae0d2",
        prefix: "",
        suffix: "",
        file_size: 5,
        haveError: false,
      },
      {
        title: "Documents for fundraiser",
        other_inputs: [],
        input_type: "file",
        input_slug: "common_documents",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "8d0f3659-7f5b-47d5-a955-0def50e60d94",
        prefix: "",
        suffix: "",
        file_size: 5,
        haveError: false,
      },
      {
        title: "Upload Government Documents",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_govt_documents",
        is_accordian: true,
        is_required: true,
        is_disabled: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "dc296df1-ba7f-47a1-a046-043c1e06bd01",
        prefix: "",
        suffix: "",
        file_size: 5,
        haveError: false,
      },
    ],
    id: "0eca8058-bcff-4ca1-8ff0-7ad401b1a2a1",
    visible: ["user", "donor", "volunteer", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Personal Details",
    is_accordian: true,
    help_title: "Personal Details",
    inputs: [
      {
        title: "Your Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "your_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "503aed3c-0b02-4fae-9a8e-45d7264e9031",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Enter phone number",
        other_inputs: [],
        input_type: "string",
        input_slug: "enter_phone_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 15,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "98d5e0e4-ba54-4276-a2c7-101a75d3db5d",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Address",
        other_inputs: [],
        input_type: "location",
        input_slug: "guest_address",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "4b353eb0-4f58-4c09-b1c2-86361905c3fc",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "42b1d03b-8bce-43c8-83c0-45a90757c482",
    visible: ["guest"],
    haveError: false,
  },
];

export const EducationNew: any = [
  {
    title: "Outer Section",
    is_accordian: false,
    help_title: "Outer Section",
    inputs: [
      {
        title: "Are you the one that needs help?",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "request_for_self",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        is_accordian: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7f540c1a-9bf1-47bf-a246-e0ab2cd90db1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "e8cb85b7-4d65-428d-a275-19ce6362cd67",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Outer Section",
    is_accordian: false,
    help_title: "Outer Section",
    inputs: [
      {
        title: "Urgent Help",
        other_inputs: [
          {
            other_title: "Description",
            other_input_slug: "description",
            other_input_type: "textarea",
            other_min: 10,
            other_is_required: true,
            other_max: 200,
          },
        ],
        input_type: "checkbox",
        input_slug: "urgent_help",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        dependant_value: "",
        is_accordian: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c3dffa82-4cfd-47e3-bb4f-bfe095a56399",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "4a4c71ed-3ec4-4298-b273-6bced8fcfdba",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Fundraiser Details",
    help_title: "Fundraiser Details",
    inputs: [
      {
        title: "Fundraiser Title",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c8d209f2-0872-49c1-b695-4d23eef0567f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Relation With Beneficiary",
        other_inputs: [],
        input_type: "select",
        input_slug: "relation_with_beneficiary",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "Brother",
          "Daughter",
          "Father",
          "Friend",
          "Husband",
          "Mother",
          "Sister",
          "Son",
          "Wife",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6eed1e18-346f-4f77-8e2d-6d5206094f55",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        is_hidden: true,
        select_type: "true",
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "First name",
        other_inputs: [],
        input_type: "string",
        input_slug: "first_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "418bd2db-da67-4341-b11e-36a9fc6cb82b",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Last name",
        other_inputs: [],
        input_type: "string",
        input_slug: "last_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "bb6d508c-6326-4abb-aa93-e71d9ffe353e",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Age",
        other_inputs: [],
        input_type: "number",
        input_slug: "age",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 1,
        max: 98,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c64d4586-1051-4fab-ac61-88441432cddb",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        is_hidden: true,
        select_type: "true",
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Beneficiary Location",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "request_for_self",
        dependant_value: "false",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "db8be8a3-f31c-43eb-b588-e0ab142d728e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Race",
        other_inputs: [],
        input_type: "select",
        input_slug: "race",
        is_required: false,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Race"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a0a1481e-f973-41d5-8d86-e4192576e352",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Religion",
        other_inputs: [],
        input_type: "select",
        input_slug: "religion",
        is_required: false,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Religion"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d37b80b2-1856-45c7-b4be-7cae63de491e",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Choose your Education/Applying for",
        other_inputs: [],
        input_type: "select",
        input_slug: "choose_or_select_institute",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "Bachelor's degree",
          "Certificate",
          "Diploma",
          "Postgraduate study",
          "Masters",
          "Std 1 to 7",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "4f5b6e56-87e8-4c61-9c4f-3052a6ae60f7",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Do you already have an admission?",
        other_inputs: [],
        input_type: "radio",
        input_slug: "already_admission",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Yes", "No"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "e159b755-38af-48b6-bd9a-389a436d814e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Saayam supported College/ School",
        other_inputs: [],
        input_type: "select",
        input_slug: "saayam_supported_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        dependant_type: "not_listed",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "IISc Bangalore",
          "JNU",
          "Banaras Hindu University",
          "Amrita Vishwa Vidyapeetham",
          "Tata Institute of Fundamental Research",
          "Indian Institute of Technology - Bombay",
          "University of Delhi",
          "Panjab University",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2e1f031d-6ec5-4ac0-bffb-2fe1a30cf0aa",
        prefix: "",
        suffix: "",
        key: "",
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "College/ School is not listed",
        other_inputs: [
          {
            other_title: "Enter College Name",
            other_input_type: "string",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
            other_input_slug: "saayam_supported_name",
          },
          {
            other_title: "College (Location)",
            other_input_type: "location",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
            other_input_slug: "location",
          },
          {
            other_title: "Enter Student ID / Enrollment",
            other_input_type: "string",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
            other_input_slug: "student_id",
          },
          {
            other_title: "Enter your Grade or Semester",
            other_input_type: "string",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
            other_input_slug: "semester",
          },
        ],
        input_type: "checkbox",
        input_slug: "not_listed",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "saayam_supported_college_school",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        min: 0,
        max: 0,
        options: ["College/ School is not listed"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9db9f77e-dd1c-4618-ad33-84ffb2a8d99f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Specify the course name",
        other_inputs: [],
        input_type: "string",
        input_slug: "specify_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "not_listed",
        dependant_value: "true",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7290eeb1-9c09-4662-9584-162ce7a35056",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Currently I'm pursing specify_name",
        other_inputs: [],
        input_type: "radio",
        input_slug: "currently_pursing",
        is_required: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: true,
        merge_string: "specify_name",
        string_to_be_replace: "specify_name",
        second_merge_string: "",
        dependant_type: "already_admission",
        dependant_value: "Yes",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Yes", "No"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "11fb2b72-96cf-4872-a213-5c35c97e89e4",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Select course provided by saayam_supported_name",
        other_inputs: [],
        input_type: "select",
        input_slug: "specify_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        dependant_type: "not_listed",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "BBA",
          "Engineering – BE, B.Tech and B.Arch",
          "Computer Application-BCA",
          "Designing – Fashion/Interior/Web",
          "Mass-Communication/Journalism BJMC",
          "Medical-BDS and MBBS",
          "Finance -B.Com/CA",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "018a7f60-c5b2-47a0-aeea-80bbfbba6aa0",
        prefix: "",
        suffix: "",
        key: "",
        dependant_value: "false",
        display_joint_name: true,
        string_to_be_replace: "saayam_supported_name",
        merge_string: "saayam_supported_name",
        haveError: false,
      },
      {
        title: "Specify your course amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "229f57d7-c242-472c-bc4b-6cfd757702b7",
        prefix: "",
        suffix: "",
        key: "",
        add_suffix: true,
        haveError: false,
      },
      {
        title: "Need funds by",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cb19ad97-7d9e-4e31-9aa6-b60b27cdc269",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "What's the aim of this fundraiser",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 5,
        multiselect: false,
        id: "cee1f498-10b3-47f2-9a37-aa1fc8b6f3c8",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Upload Cover Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cda5cc3c-0606-47ff-b8ac-3516a847333d",
        prefix: "",
        suffix: "",
        key: "",
        is_other_lable: true,
        other_label_value:
          "Provide the required information to verify that the fundraiser is genuinely contributing to or supporting a cause.",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Photos",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "04fdb016-7d6d-4fc7-b0dc-e89ccde06d71",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 1,
        options: [""],
        file_type: "video",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "850bf9c9-6433-42cc-9f16-a2ccf055faf3",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 1000,
        video_duration: 30,
        haveError: false,
      },
    ],
    id: "faad8b11-47d6-45b2-9639-8f74a08627ef",
    visible: ["donor", "volunteer", "ngo", "user", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Documents",
    help_title: "Documents",
    inputs: [
      {
        title: "Admission & Fee",
        other_inputs: [],
        input_type: "file",
        input_slug: "admission_and_fee",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/pdf",
          "application/vnd.ms-powerpoint",
          "image/*",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "94a1b276-3918-4550-8056-7ecff1a7a70f",
        prefix: "",
        suffix: "",
        key: "",
        is_other_lable: true,
        other_label_value:
          "Provide the required information to verify that the fundraiser is genuinely contributing to or supporting a cause.",
        file_size: 5,
        haveError: false,
      },
      {
        title: "Add Government Proof",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_govt_documents",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "0c44716a-04e4-4da9-ab86-f0695f2e3d1f",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 3,
        haveError: false,
      },
    ],
    id: "8ca0fbe0-f1f4-46ce-9e22-eac1aff81dae",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Personal Details",
    is_accordian: true,
    help_title: "Personal Details",
    inputs: [
      {
        title: "Your Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "your_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7dc33c1e-b820-438a-a631-bf1e5d9f55af",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Enter Phone Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "enter_phone_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 15,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "03fc44ab-12f8-48b6-9175-18ea7e5c6468",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Address",
        other_inputs: [],
        input_type: "location",
        input_slug: "guest_address",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "0fc29772-b980-436d-a86d-1e6d6be9ebad",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "51b523fc-9791-42c6-a0e4-1c051f24a584",
    visible: ["guest"],
    haveError: false,
  },
];

export const HealthNew: any = [
  {
    title: "Outer Section",
    is_accordian: false,
    help_title: "Outer Section",
    inputs: [
      {
        title: "Are you the one that needs help?",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "request_for_self",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        is_accordian: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7f540c1a-9bf1-47bf-a246-e0ab2cd90db1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "e8cb85b7-4d65-428d-a275-19ce6362cd67",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Outer Section",
    is_accordian: false,
    help_title: "Outer Section",
    inputs: [
      {
        title: "Urgent Help",
        other_inputs: [
          {
            other_title: "Description",
            other_input_type: "textarea",
            other_min: 10,
            other_is_required: true,
            other_max: 200,
            other_input_slug: "description",
          },
        ],
        input_type: "checkbox",
        input_slug: "urgent_help",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        select_type: "",
        is_accordian: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c2ecb793-ecf7-4ba8-879e-123217c742a1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "3ad42da4-700b-432c-ac96-af8628679052",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Fundraiser Details",
    help_title: "Fundraiser Details",
    inputs: [
      {
        title: "Fundraiser Title",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        is_disabled: false,
        is_accordian: true,
        is_dependant: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b160508c-4249-475a-ad54-d1cfd90ac1e8",
        prefix: "",
        suffix: "",
        dependant_type: "",
        haveError: false,
      },
      {
        title: "Relation With Beneficiary",
        other_inputs: [],
        input_type: "select",
        input_slug: "relation_with_beneficiary",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "Brother",
          "Daughter",
          "Father",
          "Friend",
          "Husband",
          "Mother",
          "Sister",
          "Son",
          "Wife",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6eed1e18-346f-4f77-8e2d-6d5206094f55",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        is_hidden: true,
        select_type: "true",
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "First name",
        other_inputs: [],
        input_type: "string",
        input_slug: "first_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "418bd2db-da67-4341-b11e-36a9fc6cb82b",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Last name",
        other_inputs: [],
        input_type: "string",
        input_slug: "last_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "bb6d508c-6326-4abb-aa93-e71d9ffe353e",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Age",
        other_inputs: [],
        input_type: "number",
        input_slug: "age",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 1,
        max: 98,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c64d4586-1051-4fab-ac61-88441432cddb",
        prefix: "",
        suffix: "",
        haveError: false,
        dependant_type: "request_for_self",
        dependant_value: "false",
      },
      {
        title: "Beneficiary Location",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "request_for_self",
        dependant_value: "false",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "bef1d5b9-53f9-4767-880f-3e76e8c6db29",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Race",
        other_inputs: [],
        input_type: "select",
        input_slug: "race",
        is_required: false,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        is_accordian: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Race"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a0a1481e-f973-41d5-8d86-e4192576e352",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Religion",
        other_inputs: [],
        input_type: "select",
        input_slug: "religion",
        is_required: false,
        is_disabled: false,
        is_dependant: true,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Religion"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d37b80b2-1856-45c7-b4be-7cae63de491e",
        prefix: "",
        suffix: "",
        dependant_type: "request_for_self",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "Patient's medical condition",
        other_inputs: [],
        input_type: "select",
        input_slug: "choose_or_select_institute",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          "Arthritis",
          "Asthma",
          "Blindness",
          "Cancer",
          "Chronic Bronchitis",
          "Chronic Kidney Disease",
          "Coronary Heart Disease",
          "Multiple Sclerosis",
          "Other",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: true,
        id: "05ddbad6-5cb7-43e9-a5a2-f91cd29ca5f1",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Tell your back story",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 5,
        multiselect: false,
        id: "3d15ef2b-7ab6-4c08-9500-047af4175459",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Estimate amount for fundraiser",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        is_accordian: true,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d24389aa-e00d-49f0-bc05-9335a6ce1213",
        prefix: "",
        suffix: "",
        add_suffix: true,
        haveError: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
      },
      {
        title: "Need funds by",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_mobile: false,
        is_accordian: true,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "e64ccbef-2f29-4f12-8b28-eefb47d462e9",
        prefix: "",
        suffix: "",
        haveError: false,
      },
      {
        title: "Upload Cover Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "09407fe8-93ec-4598-a531-62d9a1c3ea0d",
        prefix: "",
        suffix: "",
        is_other_lable: true,
        other_label_value:
          "Provide the required information to verify that the fundraiser is genuinely contributing to or supporting a cause.",
        is_beside_main_title: "below",
        file_size: 10,
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 1,
        options: [""],
        file_type: "video",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "25eaef52-f329-44a4-b0c0-303e43afba78",
        prefix: "",
        suffix: "",
        is_other_lable: true,
        other_label_value: "(Make video appeal to connect with Doners)",
        is_beside_main_title: "right",
        file_size: 1000,
        video_duration: 30,
        haveError: false,
      },
    ],
    id: "63bc0e23-7cae-489a-bc87-a3495fdf047f",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Hospital Details",
    help_title: "Hospital Details",
    inputs: [
      {
        title: "Saayam Supported Hospitals",
        other_inputs: [],
        input_type: "select",
        input_slug: "saayam_supported_name",
        is_required: true,
        is_disabled: false,
        is_dependant: true,
        dependant_type: "not_listed",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        is_accordian: true,
        min: 0,
        max: 0,
        options: [
          "Shivam Hospital",
          "Krishna Hospital",
          "Amee Multispeciality Hospital",
          "Shlok Multispeciality Hospital",
          "Jayant Shah Hospital",
          "Rudra Hospital",
          "Shaishav children hospital and vaccination centre",
          "Shreenath Hospital",
          "Surbhi Hospital",
          "Dental care clinic Sanjay Hospital",
        ],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "fe2c7eaa-0cc2-4f55-acd8-ec0c001fc2de",
        prefix: "",
        suffix: "",
        key: "",
        select_type: "true",
        is_hidden: true,
        dependant_value: "false",
        haveError: false,
      },
      {
        title: "My  Hospital is not listed",
        other_inputs: [
          {
            other_title: "Enter Hospital Name",
            other_input_type: "string",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
            other_input_slug: "saayam_supported_name",
          },
          {
            other_title: "Hospital Location",
            other_input_slug: "location",
            other_input_type: "location",
            other_min: 1,
            other_is_required: true,
            other_max: 100,
          },
        ],
        input_type: "checkbox",
        input_slug: "not_listed",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "saayam_supported_hospitals",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        is_accordian: true,
        min: 0,
        max: 0,
        options: ["My  Hospital Is not listed"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "58799117-7144-4625-b78a-c768b3e25c09",
        prefix: "",
        suffix: "",
        key: "",
        select_type: "string",
        is_hidden: false,
        display_joint_name: false,
        merge_string: "saayam_supported_name",
        haveError: false,
      },
      {
        title: "Hospital/Doctor Reference Contact",
        other_inputs: [],
        input_type: "string",
        input_slug: "reference_phone_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 15,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c1a61c5a-9d60-43de-9534-52636fc6a061",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Ailment",
        other_inputs: [],
        input_type: "string",
        input_slug: "ailment",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b8fbcd75-4c62-4bae-8395-4f3187bb0a34",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Already admitted to saayam_supported_name?",
        other_inputs: [],
        input_type: "radio",
        input_slug: "already_admission",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Yes", "No"],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c45b49cb-c185-4de6-84d3-f9e0ef00c314",
        prefix: "",
        suffix: "",
        key: "",
        display_joint_name: true,
        string_to_be_replace: "saayam_supported_name",
        merge_string: "saayam_supported_name",
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        haveError: false,
      },
      {
        title: "Patient's UHID Number (Optional)",
        other_inputs: [],
        input_type: "string",
        input_slug: "uhid_number",
        is_required: false,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "24240e45-ae03-460e-b58d-c6734eba05e6",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "79c408e1-385e-4b3d-b027-20e31d24adf6",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Medical Documents",
    help_title: "Medical Documents",
    inputs: [
      {
        title: "Medical Estimate",
        other_inputs: [],
        input_type: "file",
        input_slug: "common_documents",
        is_required: true,
        is_disabled: false,
        is_accordian: true,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/vnd.ms-powerpoint",
          "application/pdf",
          "application/msword",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d73ed6b5-065e-4e5c-9b27-03c2618064d6",
        prefix: "",
        suffix: "",
        key: "",
        is_other_lable: true,
        other_label_value:
          "Provide the required information to verify that the fundraiser is genuinely contributing to or supporting a cause.",
        is_beside_main_title: "above",
        file_size: 5,
        haveError: false,
      },
      {
        title: "Other Document",
        other_inputs: [],
        input_type: "file",
        input_slug: "other_document",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "pdf",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3f8ebc25-2252-4b44-a5cf-e9e865eac9b5",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 5,
        haveError: false,
      },
    ],
    id: "c0af2c32-a932-48d7-bbf9-08d488da3a0c",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Patient Verification",
    help_title: "Patient Verification",
    inputs: [
      {
        title: "Patient's id proof",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_govt_documents",
        is_required: true,
        is_disabled: false,
        is_dependant: false,
        dependant_type: "",
        is_display: false,
        is_accordian: true,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "photo",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_tag: "front",
        num_of_row: 0,
        multiselect: false,
        id: "ef7248d1-f6fd-46a6-b56e-254f8d06d840",
        prefix: "",
        suffix: "",
        key: "",
        file_size: 10,
        haveError: false,
      },
    ],
    id: "306d6905-4f9a-4a31-9108-787f5047b2b2",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    is_accordian: true,
    haveError: false,
  },
  {
    title: "Personal Details",
    is_accordian: true,
    help_title: "Personal Details",
    inputs: [
      {
        title: "Your Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "your_name",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a245715d-2d55-4580-8b90-c1e35c39d3fe",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Enter Phone Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "enter_phone_number",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 15,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b0a87ce0-6f44-43d2-ae1d-76d5f22965f3",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Address",
        other_inputs: [],
        input_type: "location",
        input_slug: "guest_address",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "e2a9cd95-b280-40fd-a140-46420096c87a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "0e24eed7-71ec-447d-9fd4-0bb7557665ba",
    visible: ["guest"],
    haveError: false,
  },
];

export const DisasterRelief: any = [
  {
    title: "Fundraiser Details",
    is_accordian: true,
    help_title: "Fundraiser Details",
    inputs: [
      {
        title: "Type of disaster",
        other_inputs: [],
        input_type: "select",
        input_slug: "type_of_disaster",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "earthquake",
            value: "Earthquake",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a12ac6c6-56eb-4b97-b77d-b9b97d691b4e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Name your Fundraiser",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 200,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "15b69a8a-d3cb-4c9b-a7eb-ad330ffc0ccc",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "What Happened",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 20,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "789a53ed-4966-4ac9-acb1-dde1667a1e03",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Where Did it Happen",
        other_inputs: [],
        input_type: "location",
        input_slug: "where_did_it_happen",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9234f7d8-c5e7-44c3-9a0d-f757a251ae07",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "How much area are Impacted?",
        other_inputs: [],
        input_type: "slider",
        input_slug: "how_many_miles_are_impacted",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 1,
        max: 100,
        options: [
          {
            option_slug: "0_-_10km",
            value: "0 - 10km",
          },
          {
            option_slug: "25km",
            value: "25km",
          },
          {
            option_slug: "50km",
            value: "50km",
          },
          {
            option_slug: "100__km",
            value: "100+ km",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "4854d503-5c53-419d-a56f-7fc9d0fb9b0f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Approx. No of people impacted",
        other_inputs: [],
        input_type: "number",
        input_slug: "approx_people_impacted",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 10000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2942d429-f835-4b5d-895a-81eac6913e70",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "382dc537-642e-47ca-886e-e015aab47b2c",
    visible: ["donor", "ngo", "corporate", "user", "volunteer", "guest"],
    haveError: false,
  },
  {
    title: "Immediate needs",
    is_accordian: true,
    help_title: "Immediate needs",
    inputs: [
      {
        title: "Add the most immediate need your required",
        other_inputs: [],
        input_type: "title",
        input_slug: "add_the_most_immediate_need_your_required",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "aea3a933-4d7a-4fed-9b98-6810a09495a9",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Volunteer Help Required?",
        other_inputs: [],
        input_type: "toggle_switch",
        input_slug: "volunteer_help_required",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d67d44a3-c21e-4f0e-861a-67cb6a2a3e1f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
        is_bold: true,
      },
      {
        title: "Number of volunteers required",
        other_inputs: [],
        input_type: "number",
        input_slug: "no_volunteer_required",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a4433562-7cd5-420e-8bb4-ae2966ba3258",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Food & water aid support?",
        other_inputs: [],
        input_type: "toggle_switch",
        input_slug: "food_and_water_aid_support",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "23f7302a-972c-483c-980a-1bdbf5e93fe4",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
        is_bold: true,
      },
      {
        title: "Number of people requiring this aid",
        other_inputs: [],
        input_type: "number",
        input_slug: "no_of_peoples_required_aid",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "food_and_water_aid_support",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 10000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3aaecf9f-812d-4c5d-9fc4-d8c7aa8d3ee1",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "We accept clothes donation",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "we_accept_clothes_donation",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "food_and_water_aid_support",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "77a871cb-23e6-41f0-85b9-93be76fa4687",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Add monetary support",
        other_inputs: [],
        input_type: "toggle_switch",
        input_slug: "add_monetary_support",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "1d10d6e5-0fe3-417a-a20f-582f75969ff1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
        is_bold: true,
      },
      {
        title: "Need Funds By",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "add_monetary_support",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "1be79cc8-8b16-4a02-8336-30169d159630",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Goal amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        is_required: true,
        add_suffix: true,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "add_monetary_support",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "851b3e1e-08a7-43b4-b4aa-b223051dc9ee",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Fundraiser closure date",
        other_inputs: [],
        input_type: "date",
        input_slug: "fundraiser_closure_date",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "0",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: true,
        greater_than_date: "expiry_date",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2c399ff4-8070-4aa0-b416-081f209a635f",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "c8af6818-bae8-49ec-89e0-194065101bfd",
    visible: ["user", "donor", "volunteer", "ngo", "corporate", "guest"],
    haveError: false,
  },
  {
    title: "Fundraiser Setup",
    is_accordian: true,
    help_title: "Fundraiser Setup",
    inputs: [
      {
        title: "Add Cover Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 30,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3bdeb630-ca98-4d8f-a709-128998f5e1c8",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Add Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_size: 30,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "98e0f0ff-ff8d-4919-bafb-63786ec4ff2b",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Add Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 40,
        video_duration: 30,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "e10888b4-7c46-4cb3-8f22-2096bb0c8e46",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "5950bea5-5480-4fb5-b8c1-16093e5e0fc7",
    visible: ["guest", "user", "donor", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
];

export const ElderlyCare: any = [
  {
    title: "Fundraiser Details ",
    is_accordian: true,
    help_title: "Fundraiser Details ",
    inputs: [
      {
        title: "Name your fundraiser",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9df97f11-b46b-406f-bfe5-e79c5217a5c9",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Why are you creating this fundraiser",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "eaa36ec9-f035-4327-9859-a483b425ec1a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Selection options for Elderly Care Initiative: ",
        other_inputs: [],
        input_type: "tooltip",
        input_slug: "selection_options_for_elderly_care_initiative",
        regex_string: "",
        tooltip_description:
          "<ul>\n<li>Medical Assistance: Ensuring access to healthcare services, medications, and regular check-ups.</li>\n<li>Companionship Programs: Establishing programs to combat loneliness through social interactions, companionship, and recreational activities.</li>\n<li>Home Modifications: Adapting living spaces to ensure safety and convenience for seniors with mobility issues.</li>\n<li>Nutritional Support: Providing nutritious meals and dietary supplements to promote their health and well-being.</li>\n<li>Emergency Response Systems: Implementing systems that ensure quick response in case of emergencies or medical concerns.</li>\n</ul>",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ddab17d4-3d6f-4253-af57-6171e50811f5",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Selection options for Elderly Care Initiative: ",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "options_for_elderly_care",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: true,
        id: "b3cd3c90-e749-4aa3-b043-4ab63093898a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Approx. no of beneficiaries",
        other_inputs: [],
        input_type: "slider",
        input_slug: "no_of_beneficiaries",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a1bf2f85-0ef4-4a47-9785-7dea8847a535",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "8e079974-7022-4658-95cc-0450e3a89efb",
    visible: ["guest", "user", "donor", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
  {
    title: "Immediate Needs",
    is_accordian: true,
    help_title: "Immediate Needs",
    inputs: [
      {
        title: "Add the most immediate need your required",
        other_inputs: [],
        input_type: "label",
        input_slug: "add_the_most_immediate_need_your_required",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cc9aa069-d984-476a-ba32-41f3323ae5ab",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Add monetary support",
        other_inputs: [],
        input_type: "toggle_switch",
        input_slug: "add_monetary_support",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: true,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "",
            value: "",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d6e35be5-9c55-423f-be61-0bd42910cd5a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Goal amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "add_monetary_support",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3811f6aa-1d58-4a12-8e8f-c060d5ed98bc",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Need Funds By",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "1440",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "add_monetary_support",
        is_date_greater: true,
        greater_than_date: "start_date",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "bd94692e-0cd6-478c-a4f2-dd0557106317",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Volunteer help required?",
        other_inputs: [],
        input_type: "toggle_switch",
        input_slug: "volunteer_help_required",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: true,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "02b396f0-1048-4090-83d1-1af36fd51e4a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "No. of how much volunteer required",
        other_inputs: [],
        input_type: "number",
        input_slug: "no_volunteer_required",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "0ed709ae-56c2-4be0-b3e3-27d8fd572079",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Select start date",
        other_inputs: [],
        input_type: "date",
        input_slug: "start_date",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "82a80ede-7898-44c4-b693-a332a90fc112",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Select end date",
        other_inputs: [],
        input_type: "date",
        input_slug: "end_date",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "1440",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: true,
        greater_than_date: "start_date",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "43b47945-3106-45d2-ab05-fc4522573162",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Add location",
        other_inputs: [],
        input_type: "location",
        input_slug: "voluteer_location",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "5f6ef054-2606-466e-9cd6-e9b5e31170af",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Don’t start drive if nobody join's the drive",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "dont_start_drive",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: true,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b63327fe-888d-480a-88d9-148714958e97",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Allow users to join the drive if drive is started",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "allow_users_to_join",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: true,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "965b7656-32af-421f-9074-08f4d15ba51f",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Add message for volunteer",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "message_for_volunteer",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "volunteer_help_required",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 20000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "95881a3e-3521-4c69-83cc-50b42d1c61df",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
    ],
    id: "641446e7-3354-470d-86d3-307760af8c27",
    visible: ["user", "guest", "donor", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
  {
    title: "Fundraiser Setup ",
    is_accordian: true,
    help_title: "Fundraiser Setup ",
    inputs: [
      {
        title: "Add cover photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "26ea2d30-d62f-4bb9-ac1f-b9fe07100708",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "photo",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "8229b899-f4d3-4b31-910b-ceba795ba6ab",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 30,
        video_duration: 30,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "10bbc4fd-502b-4a3d-889e-cd2a93cb3b42",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Supporting document to fundraiser",
        other_inputs: [],
        input_type: "file",
        input_slug: "supporting_document_to_fundraiser",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "pdf",
        file_size: 20,
        video_duration: 0,
        file_access_type: ["image/*", "application/pdf"],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "83603543-339d-4f35-b97d-e5e779f80278",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "d506d5cc-d05e-4e68-b2f8-b56a2389aa0b",
    visible: ["guest", "user", "donor", "ngo", "volunteer", "corporate"],
    haveError: false,
  },
];

export const WomenEmpowerment: any = [
  {
    title: "Get Started",
    is_accordian: true,
    help_title: "Get Started",
    inputs: [
      {
        title: "Area of women empowerment would you like to support?",
        other_inputs: [],
        input_type: "radio",
        input_slug: "area_of_women_empowerment",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "health_and_wellness",
            value: "Health and Wellness",
          },
          {
            option_slug: "economic_empowerment",
            value: "Economic Empowerment",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "954c087f-8c0f-42de-b74f-f31ac4f04b7c",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "What help is required for option_name",
        other_inputs: [],
        input_type: "label",
        input_slug: "what_help_is_required_for",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: true,
        merge_string: "area_of_women_empowerment",
        string_to_be_replace: "option_name",
        second_merge_string: "",
        dependant_type: "area_of_women_empowerment",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c9666c9c-a168-41f1-89e6-e6963500c953",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Raising fundraiser for",
        other_inputs: [],
        input_type: "select",
        input_slug: "raising_fundraiser_for",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "raising_fundraiser_for_community",
            value: "Raising fundraiser for community",
          },
          {
            option_slug: "raising_fundraiser_for_group",
            value: "Raising fundraiser for group",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2c43a554-414c-46dc-ae94-a524faec5379",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "No. of women will get benefit",
        other_inputs: [],
        input_type: "number",
        input_slug: "no__of_women_will_get_benefit",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "53e61d63-9897-47cb-b814-a600b24d2c4e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "375ba84c-977f-4ac3-b133-2700b6b8129c",
    visible: ["ngo", "volunteer", "donor", "user", "corporate"],
    haveError: false,
  },
  {
    title: "Fundraiser Details",
    is_accordian: true,
    help_title: "Fundraiser Details",
    inputs: [
      {
        title: "Name your fundraiser",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "f73227d4-4338-40df-9360-10588e813c51",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "What changes this fundraiser will bring",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 20,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "af1bae9b-6aeb-40af-bf52-ddcde7b1e491",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Challenges faced by a women",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "challenges_face_by_a_women",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 20,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "d6d28fec-eebf-41e6-a427-d945c3e2e30e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Need Funds By",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "8e938af4-5924-468e-8efa-992523dedcaa",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Fund amount required",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        is_required: true,
        add_suffix: true,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 999999,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "46f3785f-9ff3-41de-ad61-a8748b27867e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "04dc82d1-63ca-4c8c-95c1-b2a65f14af9d",
    visible: ["user", "donor", "volunteer", "ngo"],
    haveError: false,
  },
  {
    title: "Fundraiser setup",
    is_accordian: true,
    help_title: "Fundraiser setup",
    inputs: [
      {
        title: "Add cover photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b4f37753-9273-4f7e-b398-bb369c527476",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Add Photos",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "e188cf0e-0f23-41e7-98ef-1ca50c5d8aaf",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Add video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 30,
        video_duration: 30,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "53844425-86f7-40a6-ae82-39949518fc0a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "386715d0-5faf-48da-bccd-3b679340fd73",
    visible: ["user", "donor", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
];

export const HumanRights: any = [
  {
    title: "Fundraiser Details",
    is_accordian: true,
    help_title: "Fundraiser Details",
    inputs: [
      {
        title: "Are you the one that needs help?",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "request_for_self",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "are_you_the_one_that_needs_help",
            value: "Are you the one that needs help?",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "469c24c8-d82c-4cf0-8ee5-9caa1749777a",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Urgent help",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "urgent_help",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "urgent_help",
            value: "Urgent help",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "990cf43a-06a5-4c42-86fc-9b02caad1a25",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Description",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "description",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "urgent_help",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "d1e828c0-3979-4a86-a010-8e0793d481e9",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Type of Human rights",
        other_inputs: [],
        input_type: "select",
        input_slug: "type_of_human_rights",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [
          {
            option_slug: "type_of_human_rights",
            value: "Type of Human rights",
          },
        ],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "13fb8704-952e-4480-a8ed-f180b39d69cd",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Name your fundraiser",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "aa98b136-4229-41bf-8174-d69824c27091",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Why are you creating this fundraiser",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "write_your_story",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "b6b21efc-60a5-4921-b0cc-b18e7ebb74ea",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Fundraiser Outcome",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "fundraiser_outcome",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "22c54698-e787-4b71-a338-20e68a8aaead",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Goal amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "goal_amount",
        regex_string: "",
        is_required: true,
        add_suffix: true,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "fb04fda1-3446-40f7-aba8-c923178c8c48",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Need Funds By",
        other_inputs: [],
        input_type: "date",
        input_slug: "expiry_date",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3474a77b-527f-4637-a298-82e20a5e1ab3",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "e7e9a168-ae38-4115-9431-fc512ef38eb0",
    visible: ["guest", "donor", "user", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
  {
    title: "Fundraiser setup ",
    is_accordian: true,
    help_title: "Fundraiser setup ",
    inputs: [
      {
        title: "Add cover photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_cover_photo",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "afea6843-1f89-4643-9c7e-498081cb122c",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Photo",
        other_inputs: [],
        input_type: "file",
        input_slug: "photo",
        regex_string: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cc26b8b7-01a9-4a80-ac49-0c1012255e64",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 30,
        video_duration: 30,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "65f5558d-2c7e-4b06-9d8e-dfe35c139e34",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Supporting document to fundraiser",
        other_inputs: [],
        input_type: "file",
        input_slug: "supporting_document_to_fundraiser",
        regex_string: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "pdf",
        file_size: 20,
        video_duration: 0,
        file_access_type: ["application/pdf", "image/*"],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "29f96eec-65b5-4e2a-8839-fb01551f354c",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "8d9cebd1-cbec-4410-9c45-b5b44059ce35",
    visible: ["guest", "user", "donor", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
];

export const StartFund: any = [
  {
    title: "Step 3",
    is_accordian: true,
    help_title: "Step 3",
    inputs: [
      {
        title: "Name your Fund",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 30,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "353a33fa-1d0d-4c96-82c2-bf9e4c7837ec",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Describe your Fund",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "describe_your_fund",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "f441ab6d-f4aa-4de1-8b79-332bb81bb58d",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "How the funds will be used",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "how_the_funds_will_be_used",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "09e194dc-f890-4f59-8803-e643333ff2f1",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Photos",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 2,
        options: [""],
        file_type: "photo",
        file_size: 10,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "110ff73b-bfa6-4268-8e24-8a5f877db633",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 30,
        video_duration: 60,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "72039ea1-1674-4b58-9eec-73a8da0aaae6",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "2962d8d4-1c25-40ef-8481-e7e8ca232d9f",
    visible: ["user", "donor", "volunteer", "ngo"],
    haveError: false,
  },
];

export const SaayamDrive: any = [
  {
    title: "Set your drive",
    is_accordian: true,
    help_title: "Set your drive",
    inputs: [
      {
        title: "Select Drive Type",
        other_inputs: [],
        input_type: "select",
        input_slug: "drive_type",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Select Drive Type"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "78e2a71d-8fb7-47f4-95a4-0dfd67ebbb66",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "This Drive is suitable for",
        other_inputs: [],
        input_type: "radio",
        input_slug: "suitable_for",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: true,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Group", "Individual", "Both"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d5211aeb-aa7e-4f69-a8a3-f4ba1c8c75af",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Start Date-Time",
        other_inputs: [],
        input_type: "dateTime",
        input_slug: "start_date_time",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: true,
        is_beside_main_title: "below",
        other_label_value: "This date will be based on your current timezone",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "712a3c14-ca48-4d3e-8fbf-29b8926f01dd",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "End Date-Time",
        other_inputs: [],
        input_type: "dateTime",
        input_slug: "end_date_time",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "start_date_time",
        select_date_value: "start_date_time",
        greater_than_date: "start_date_time",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "423f09a5-35c4-45b7-be01-724109684444",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
        is_date_greater: true,
      },
      {
        title: "Drive Location",
        other_inputs: [],
        input_type: "radio",
        input_slug: "drive_location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: true,
        min: 0,
        max: 0,
        options: ["In-Person", "Virtual", "Hybrid"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "22b3900b-2f40-4979-96b1-4bf2228f7173",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Drive Location",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        other_dependant_type: "",
        dependant_value: "In-Person",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "a99a1ea9-88d7-40fe-a393-3d6c8596f9e0",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Add Link",
        other_inputs: [],
        input_type: "string",
        input_slug: "add_link",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        other_dependant_type: "",
        dependant_value: "Virtual",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 5,
        max: 500,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2370e5e5-b45e-4c59-8b46-57d06c1e5738",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Link will available before 15 min event starts",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "link_available",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        other_dependant_type: "",
        dependant_value: "Virtual",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Link will available before 15 min event starts"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3398cd3f-aad1-4bf6-be56-bdc5b27ce248",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Drive Location",
        other_inputs: [],
        input_type: "location",
        input_slug: "location",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "Hybrid",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ebe649be-b8bd-4915-968b-10a949e74da3",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Add Link",
        other_inputs: [],
        input_type: "string",
        input_slug: "add_link",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "Hybrid",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 5,
        max: 500,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "f5dadb6c-ad16-4c39-a068-3391c4e83073",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Link will available before 15 min event starts",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "link_available",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "drive_location",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "Hybrid",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Link will available before 15 min event starts"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "fd2c4e16-ac4c-4c0f-8876-3c07b6a43b4d",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
    ],
    id: "e8499249-72e9-4090-9192-07aa10ede194",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
  {
    title: "Drive",
    is_accordian: true,
    help_title: "Drive",
    inputs: [
      {
        title: "Drive Title",
        other_inputs: [],
        input_type: "string",
        input_slug: "title_of_fundraiser",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "fd2af4bc-7334-415e-b13a-232552688454",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Drive Description",
        other_inputs: [],
        input_type: "textarea",
        input_slug: "drive_description",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 10,
        max: 2000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 3,
        multiselect: false,
        id: "65a81975-2e4d-4025-b5ad-d101058a7446",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Set Criteria for Volunteer",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "criteria_for_volunteer",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["criteria_for_volunteer"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ec680a42-97df-41cb-b113-d8c03e1c0865",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Age Restriction",
        other_inputs: [],
        input_type: "select",
        input_slug: "age_restriction",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "criteria_for_volunteer",
        dependant_value: "true",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["18-25", "25-30", "30-40", "40-50", ">50"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c82486e8-e92c-4861-ad83-1fef6538c1ca",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Gender",
        other_inputs: [],
        input_type: "select",
        input_slug: "gender",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: true,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "criteria_for_volunteer",
        dependant_value: "true",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: ["Any", "Male", "Female"],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "74bc7312-3607-4015-9e87-9f2e50bf035e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Max Number of participants",
        other_inputs: [],
        input_type: "number",
        input_slug: "max_participants",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 500,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "74e02409-bacb-4dec-bfc9-00aef70ebdca",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Photos",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 5,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "b793a9eb-4613-4cc7-96a5-f901bbde6b19",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Video",
        other_inputs: [],
        input_type: "file",
        input_slug: "video",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "video",
        file_size: 30,
        video_duration: 60,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "5c89f409-04e4-4891-8882-0cb50a75ac3b",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "81617853-5f51-4441-91d9-fed44e57741e",
    visible: ["user", "donor", "volunteer", "ngo", "guest"],
    haveError: false,
  },
];

export const IndiaBankForm: any = [
  {
    title: "India Bank",
    help_title: "India Bank",
    inputs: [
      {
        title: "Bank Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_name",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "9b1ebf37-4e38-4be1-b998-760d6acd3de1",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Account Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_account_name",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 50,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "081ee0ae-4b66-405e-b7b3-1807df0d9024",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Account Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_account_number",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 50,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "57f87a9e-8416-408c-802b-e69b26e12a16",
        prefix: "",
        suffix: "",
      },
      {
        title: "IFSC Code",
        other_inputs: [],
        input_type: "string",
        input_slug: "ifsc_code",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 30,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "db0dd758-712d-4989-95fd-bb49ca3e0d37",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Document",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [],
        file_type: "pdf",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "2f2801ac-2417-46b8-a4d2-d448b07fa0ff",
        prefix: "",
        suffix: "",
        file_access_type: [
          "image/*",
          "application/msword",
          "application/vnd.ms-powerpoint",
          "application/pdf",
        ],
        file_size: 5,
      },
    ],
    id: "b036edda-3c20-493d-b60e-10a5f1823cd9",
    visible: [],
  },
];

export const USAForm: any = [
  {
    title: "America Bank",
    help_title: "America Bank",
    inputs: [
      {
        title: "Bank Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_name",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 100,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "0670a3e6-2216-4ca1-9d82-be586ed1f0ad",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Account Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_account_name",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 50,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "14f52104-51c2-4623-adc0-9bb0c80f47d3",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Account Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "bank_account_number",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 50,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "6fe5ba2b-395f-4cf9-bbd0-959b0150287f",
        prefix: "",
        suffix: "",
      },
      {
        title: "SWIFT Code",
        other_inputs: [],
        input_type: "string",
        input_slug: "swift_code",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 30,
        options: [""],
        file_type: "",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cb8b27af-9cf6-43af-8212-7ae0edfc9a00",
        prefix: "",
        suffix: "",
      },
      {
        title: "Last 4 digits of Social Security number",
        other_inputs: [],
        input_type: "string",
        input_slug: "ssn_last_4",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 4,
        options: [""],
        file_type: "",
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "8acd5a68-c14d-44ba-92dc-333dd3d42794",
        prefix: "",
        suffix: "",
      },
      {
        title: "Date of Birth",
        other_inputs: [],
        input_type: "date",
        input_slug: "dob",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "718a8944-1db5-4bd9-9d00-2010a237f05e",
        prefix: "",
        suffix: "",
      },
      {
        title: "Bank Documents",
        other_inputs: [],
        input_type: "file",
        input_slug: "photos",
        is_required: true,
        is_disabled: false,
        is_display: false,
        is_mobile: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "pdf",
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "37029d5d-f61c-4baf-8ed3-8df8d15c6ebd",
        prefix: "",
        suffix: "",
        file_access_type: [
          "application/pdf",
          "image/*",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-powerpoint",
        ],
        file_size: 5,
      },
    ],
    id: "c5e76678-db09-4abf-8b12-4e1bd7ff6539",
    visible: [],
  },
];

export const CommonSettingForm: any = [
  {
    title: "Payment-Gateway",
    inputs: [
      {
        title: "Select Payment-Gateway:",
        error_message: "Please Select Payment-Gateway",
        input_type: "select",
        input_slug: "payment_gateway",
        multiselect: true,
        is_global: false,
        values: "",
        checkbox_lable: "Mark this Payment-Gateway as global use",
      },
      {
        title: "Platform Fee(%):",
        error_message: "Platform fee is required",
        input_type: "number",
        input_slug: "service_fee",
        is_global: false,
        values: "",
        max_range: 51,
        max_length: 50,
        checkbox_lable: "Mark this Platform fee as global use",
      },
      // {
      //   title: "Transaction Charge(%):",
      //   error_message: "Transaction Charge is required",
      //   input_type: "number",
      //   input_slug: "transaction_fee",
      //   is_global: false,
      //   values: "",
      //   max_range: 51,
      //   max_length: 50,
      //   checkbox_lable: "Mark this Transaction Charge as global use",
      // },
      // {
      //   title: "Service declaration:",
      //   error_message: "Please describe service declaration",
      //   input_type: "string",
      //   input_slug: "service_declaration",
      //   is_global: false,
      //   values: "",
      //   checkbox_lable: "Add this Service declaration as global use",
      // },
      {
        title: "Service charge & Platform fee will be use as",
        error_message: "Please select charge type",
        input_type: "radio",
        input_slug: "manage_fees",
        is_global: false,
        values: "",
        checkbox_lable: "Add this selection as global use",
      },
    ],
    section_global: false,
  },
  {
    title: "Donation",
    inputs: [
      {
        title: "Minimum donation:",
        error_message: "Please enter Minimum donation",
        input_type: "number",
        input_slug: "minimum_donation",
        is_global: false,
        values: "",
        max_range: 999999,
        max_length: 999999,
        checkbox_lable: "Add this Minimum donation as global use",
      },
      {
        title: "Maximum donation:",
        error_message: "Please enter Maximum donation",
        input_type: "number",
        input_slug: "maximum_donation",
        is_global: false,
        values: "",
        max_range: 999999,
        max_length: 999999,
        checkbox_lable: "Add this Maximum donation as global use",
      },
      {
        title: "Saayam contact number:",
        error_message: "Please enter Saayam contact number",
        input_type: "number",
        input_slug: "saayam_contact_no",
        is_global: false,
        values: "",
        is_phone: true,
        max_range: 100000000000001,
        max_length: 100000000000000,
        checkbox_lable: "Add this Saayam contact number as global use",
      },
      {
        title: "Saayam email:",
        error_message: "Please enter Saayam email",
        input_type: "string",
        input_slug: "saayam_email",
        is_global: false,
        is_email: true,
        values: "",
        checkbox_lable: "Add this Saayam email as global use",
      },
    ],
    is_add_category: true,
    section_global: false,
  },
  {
    title: "Hunger",
    inputs: [
      {
        title: "Minimum radius:",
        error_message: "Please enter Minimum radius",
        input_type: "number",
        input_slug: "radius_in_kilometer",
        is_global: false,
        values: 0,
        max_range: 51,
        max_length: 50,
        checkbox_lable: "Add this Minimum radius as global use",
      },
      {
        title: "Maximum radius:",
        error_message: "Please enter Maximum radius",
        input_type: "number",
        input_slug: "max_radius_in_kilometer",
        is_global: false,
        values: 0,
        max_range: 51,
        max_length: 50,
        checkbox_lable: "Add this Maximum radius as global use",
      },
      {
        title: "Accept timeout in minute:",
        error_message: "Please enter timeout",
        input_type: "number",
        input_slug: "accept_time_out_in_minute",
        is_global: false,
        values: 0,
        max_range: 101,
        max_length: 100,
        checkbox_lable: "Add this Accept timeout in minute as global use",
      },
    ],
    section_global: false,
  },
];

export const buttonsArr: any = [
  { name: "Approved", key: "approve", color: "success", show: true },
  {
    name: "Volunteer Accepted",
    key: "volunteer_accept",
    color: "success",
    show: true,
  },
  { name: "Completed", key: "complete", color: "success", show: true },
  {
    name: "Pending",
    key: "pending",
    color: "warning",
    show: true,
    is_hunger: true,
  },
  { name: "Reverify", key: "reverify", show: true },
  {
    name: "Rejected",
    key: "reject",
    color: "error",
    show: true,
    is_hunger: true,
  },
  {
    name: "Expired",
    key: "expired",
    color: "error",
    show: true,
    is_hunger: true,
  },
  {
    name: "Pickup",
    key: "pickup",
    color: "warning",
    show: false,
    is_hunger: true,
  },
  {
    name: "Delivered",
    key: "delivered",
    color: "success",
    show: false,
    is_hunger: true,
  },
  {
    name: "Cancelled",
    key: "cancelled",
    color: "error",
    show: true,
    is_hunger: true,
  },
  {
    name: "Waiting for Volunteer",
    key: "waiting_for_volunteer",
    color: "success",
    show: true,
  },
  {
    name: "Waiting for Verify",
    key: "waiting_for_verify",
    color: "primary",
    show: true,
  },
  { name: "Closed", key: "close", color: "success", show: true },
];

export const fundsBtnArr: any = [
  { name: "All", key: "" },
  { name: "Pending", key: "pending" },
  { name: "Approved", key: "approve" },
  { name: "Rejected", key: "reject" },
  { name: "Blocked", key: "block" },
  { name: "Cancelled", key: "cancel" },
  { name: "Waiting for Verify", key: "waiting_for_verify" },
];

export const driveBtnArr: any = [
  { name: "All", key: "" },
  { name: "Approved", key: "approve" },
  { name: "Ongoing", key: "ongoing" },
  { name: "Completed", key: "complete" },
  { name: "Cancelled", key: "cancel" },
  { name: "Blocked", key: "blocked" },
];

export const helpReqArr: any = [
  { name: "All", key: "" },
  { name: "Pending", key: "pending" },
  { name: "Approved", key: "approve" },
  { name: "Rejected", key: "reject" },
  { name: "Expired", key: "expired" },
  { name: "Waiting for Volunteer", key: "waiting_for_volunteer" },
];

export const requestCommArr = [
  { id: "reference_id", value: "Request ID" },
  { id: "_id", value: "Reference ID" },
  { id: "uname", value: "Created By" },
  { id: "active_type", value: "User Active type" },
  { id: "near_by", value: "Near By" },
  { id: "status", value: "Status" },
  { id: "createdAt", value: "Created Time" },
  { id: "updatedAt", value: "Update Time" },
  { id: "uname", value: "Updated By" },
];

export const requestHungerArr = [
  { id: "vegetarian", value: "Is Veg?", type: "bool" },
  { id: "deliver_before", value: "Deliver Before" },
  { id: "food_for_myself", value: "Food For" },
  { id: "how_many_persons", value: "Number of Persons" },
  { id: "location", value: "Request Address" }, // for rest 3
];

export const requestHealthArr = [
  { id: "location", value: "Request Address" }, // for rest 3
  { id: "title_of_fundraiser", value: "Fundraiser Title" }, // for rest 3
  { id: "is_featured", value: "Featured", type: "bool" }, // for rest 3
  { id: "name_of_beneficiary", value: "Beneficiary Name" }, // for rest 3
  { id: "days_left", value: "Days Left" }, // for rest 3
  { id: "goal_amount", value: "Goal Amount" }, // for rest 3
  { id: "remaining_amount", value: "Remaining Amount" }, // for rest 3
  { id: "already_admission", value: "Already Admitted?", type: "bool" },
  { id: "saayam_supported_name", value: "Hospital Name" },
  { id: "urgent_help", value: "Request Is Urgent?" }, // for rest 3
  { id: "urgent_help_status", value: "Urgent Help Status" }, // for rest 3
  { id: "approve_time", value: "Approved Time" }, // for rest 3
  { id: "expiry_date", value: "Expiry Date" }, // for rest 3
];

export const requestEduArr = [
  { id: "location", value: "Request Address" }, // for rest 3
  { id: "title_of_fundraiser", value: "Fundraiser Title" }, // for rest 3
  { id: "is_featured", value: "Featured", type: "bool" }, // for rest 3
  { id: "name_of_beneficiary", value: "Beneficiary Name" }, // for rest 3
  { id: "choose_or_select_institute", value: "Medical Condition" },
  { id: "days_left", value: "Days Left" }, // for rest 3
  { id: "goal_amount", value: "Goal Amount" }, // for rest 3
  { id: "remaining_amount", value: "Remaining Amount" }, // for rest 3
  { id: "already_admission", value: "Already Admission?", type: "bool" },
  { id: "saayam_supported_name", value: "Scool/College Name" },
  { id: "urgent_help", value: "Request Is Urgent?" }, // for rest 3
  { id: "urgent_help_status", value: "Urgent Help Status" }, // for rest 3
  { id: "approve_time", value: "Approved Time" }, // for rest 3
  { id: "expiry_date", value: "Expiry Date" }, // for rest 3
];

export const requestFundraisArr = [
  { id: "title_of_fundraiser", value: "Fundraiser Title" }, // for rest 3
  { id: "is_featured", value: "Featured", type: "bool" }, // for rest 3
  { id: "name_of_beneficiary", value: "Beneficiary Name" }, // for rest 3
  { id: "days_left", value: "Days Left" }, // for rest 3
  { id: "goal_amount", value: "Goal Amount" }, // for rest 3
  { id: "remaining_amount", value: "Remaining Amount" }, // for rest 3
  { id: "urgent_help", value: "Request Is Urgent?" }, // for rest 3
  { id: "urgent_help_status", value: "Urgent Help Status" }, // for rest 3
  { id: "approve_time", value: "Approved Time" }, // for rest 3
  { id: "expiry_date", value: "Expiry Date" }, // for rest 3
];

export const countryData = [
  {
    name: "Afghanistan",
    dialCode: "+93",
    isoCode: "AF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/af.svg",
  },
  {
    name: "Aland Islands",
    dialCode: "+358",
    isoCode: "AX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ax.svg",
  },
  {
    name: "Albania",
    dialCode: "+355",
    isoCode: "AL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/al.svg",
  },
  {
    name: "Algeria",
    dialCode: "+213",
    isoCode: "DZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dz.svg",
  },
  {
    name: "American Samoa",
    dialCode: "+1684",
    isoCode: "AS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/as.svg",
  },
  {
    name: "Andorra",
    dialCode: "+376",
    isoCode: "AD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ad.svg",
  },
  {
    name: "Angola",
    dialCode: "+244",
    isoCode: "AO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ao.svg",
  },
  {
    name: "Anguilla",
    dialCode: "+1264",
    isoCode: "AI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ai.svg",
  },
  {
    name: "Antarctica",
    dialCode: "+672",
    isoCode: "AQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/aq.svg",
  },
  {
    name: "Antigua and Barbuda",
    dialCode: "+1268",
    isoCode: "AG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ag.svg",
  },
  {
    name: "Argentina",
    dialCode: "+54",
    isoCode: "AR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ar.svg",
  },
  {
    name: "Armenia",
    dialCode: "+374",
    isoCode: "AM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/am.svg",
  },
  {
    name: "Aruba",
    dialCode: "+297",
    isoCode: "AW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/aw.svg",
  },
  {
    name: "Ascension Island",
    dialCode: "+247",
    isoCode: "AC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ac.svg",
  },
  {
    name: "Australia",
    dialCode: "+61",
    isoCode: "AU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/au.svg",
  },
  {
    name: "Austria",
    dialCode: "+43",
    isoCode: "AT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/at.svg",
  },
  {
    name: "Azerbaijan",
    dialCode: "+994",
    isoCode: "AZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/az.svg",
  },
  {
    name: "Bahamas",
    dialCode: "+1242",
    isoCode: "BS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bs.svg",
  },
  {
    name: "Bahrain",
    dialCode: "+973",
    isoCode: "BH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bh.svg",
  },
  {
    name: "Bangladesh",
    dialCode: "+880",
    isoCode: "BD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bd.svg",
  },
  {
    name: "Barbados",
    dialCode: "+1246",
    isoCode: "BB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bb.svg",
  },
  {
    name: "Belarus",
    dialCode: "+375",
    isoCode: "BY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/by.svg",
  },
  {
    name: "Belgium",
    dialCode: "+32",
    isoCode: "BE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/be.svg",
  },
  {
    name: "Belize",
    dialCode: "+501",
    isoCode: "BZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bz.svg",
  },
  {
    name: "Benin",
    dialCode: "+229",
    isoCode: "BJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bj.svg",
  },
  {
    name: "Bermuda",
    dialCode: "+1441",
    isoCode: "BM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bm.svg",
  },
  {
    name: "Bhutan",
    dialCode: "+975",
    isoCode: "BT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bt.svg",
  },
  {
    name: "Bolivia",
    dialCode: "+591",
    isoCode: "BO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bo.svg",
  },
  {
    name: "Bosnia and Herzegovina",
    dialCode: "+387",
    isoCode: "BA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ba.svg",
  },
  {
    name: "Botswana",
    dialCode: "+267",
    isoCode: "BW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bw.svg",
  },
  {
    name: "Brazil",
    dialCode: "+55",
    isoCode: "BR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/br.svg",
  },
  {
    name: "British Indian Ocean Territory",
    dialCode: "+246",
    isoCode: "IO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/io.svg",
  },
  {
    name: "Brunei Darussalam",
    dialCode: "+673",
    isoCode: "BN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bn.svg",
  },
  {
    name: "Bulgaria",
    dialCode: "+359",
    isoCode: "BG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bg.svg",
  },
  {
    name: "Burkina Faso",
    dialCode: "+226",
    isoCode: "BF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bf.svg",
  },
  {
    name: "Burundi",
    dialCode: "+257",
    isoCode: "BI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bi.svg",
  },
  {
    name: "Cambodia",
    dialCode: "+855",
    isoCode: "KH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kh.svg",
  },
  {
    name: "Cameroon",
    dialCode: "+237",
    isoCode: "CM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cm.svg",
  },
  {
    name: "Canada",
    dialCode: "+1",
    isoCode: "CA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ca.svg",
  },
  {
    name: "Cape Verde",
    dialCode: "+238",
    isoCode: "CV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cv.svg",
  },
  {
    name: "Cayman Islands",
    dialCode: "+1345",
    isoCode: "KY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ky.svg",
  },
  {
    name: "Central African Republic",
    dialCode: "+236",
    isoCode: "CF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cf.svg",
  },
  {
    name: "Chad",
    dialCode: "+235",
    isoCode: "TD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/td.svg",
  },
  {
    name: "Chile",
    dialCode: "+56",
    isoCode: "CL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cl.svg",
  },
  {
    name: "China",
    dialCode: "+86",
    isoCode: "CN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cn.svg",
  },
  {
    name: "Christmas Island",
    dialCode: "+61",
    isoCode: "CX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cx.svg",
  },
  {
    name: "Cocos (Keeling) Islands",
    dialCode: "+61",
    isoCode: "CC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cc.svg",
  },
  {
    name: "Colombia",
    dialCode: "+57",
    isoCode: "CO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/co.svg",
  },
  {
    name: "Comoros",
    dialCode: "+269",
    isoCode: "KM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/km.svg",
  },
  {
    name: "Congo",
    dialCode: "+242",
    isoCode: "CG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cg.svg",
  },
  {
    name: "Cook Islands",
    dialCode: "+682",
    isoCode: "CK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ck.svg",
  },
  {
    name: "Costa Rica",
    dialCode: "+506",
    isoCode: "CR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cr.svg",
  },
  {
    name: "Croatia",
    dialCode: "+385",
    isoCode: "HR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hr.svg",
  },
  {
    name: "Cuba",
    dialCode: "+53",
    isoCode: "CU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cu.svg",
  },
  {
    name: "Cyprus",
    dialCode: "+357",
    isoCode: "CY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cy.svg",
  },
  {
    name: "Czech Republic",
    dialCode: "+420",
    isoCode: "CZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cz.svg",
  },
  {
    name: "Democratic Republic of the Congo",
    dialCode: "+243",
    isoCode: "CD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/cd.svg",
  },
  {
    name: "Denmark",
    dialCode: "+45",
    isoCode: "DK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dk.svg",
  },
  {
    name: "Djibouti",
    dialCode: "+253",
    isoCode: "DJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dj.svg",
  },
  {
    name: "Dominica",
    dialCode: "+1767",
    isoCode: "DM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/dm.svg",
  },
  {
    name: "Dominican Republic",
    dialCode: "+1849",
    isoCode: "DO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/do.svg",
  },
  {
    name: "Ecuador",
    dialCode: "+593",
    isoCode: "EC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ec.svg",
  },
  {
    name: "Egypt",
    dialCode: "+20",
    isoCode: "EG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/eg.svg",
  },
  {
    name: "El Salvador",
    dialCode: "+503",
    isoCode: "SV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sv.svg",
  },
  {
    name: "Equatorial Guinea",
    dialCode: "+240",
    isoCode: "GQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gq.svg",
  },
  {
    name: "Eritrea",
    dialCode: "+291",
    isoCode: "ER",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/er.svg",
  },
  {
    name: "Estonia",
    dialCode: "+372",
    isoCode: "EE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ee.svg",
  },
  {
    name: "Eswatini",
    dialCode: "+268",
    isoCode: "SZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sz.svg",
  },
  {
    name: "Ethiopia",
    dialCode: "+251",
    isoCode: "ET",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/et.svg",
  },
  {
    name: "Falkland Islands (Malvinas)",
    dialCode: "+500",
    isoCode: "FK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fk.svg",
  },
  {
    name: "Faroe Islands",
    dialCode: "+298",
    isoCode: "FO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fo.svg",
  },
  {
    name: "Fiji",
    dialCode: "+679",
    isoCode: "FJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fj.svg",
  },
  {
    name: "Finland",
    dialCode: "+358",
    isoCode: "FI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fi.svg",
  },
  {
    name: "France",
    dialCode: "+33",
    isoCode: "FR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fr.svg",
  },
  {
    name: "French Guiana",
    dialCode: "+594",
    isoCode: "GF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gf.svg",
  },
  {
    name: "French Polynesia",
    dialCode: "+689",
    isoCode: "PF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pf.svg",
  },
  {
    name: "Gabon",
    dialCode: "+241",
    isoCode: "GA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ga.svg",
  },
  {
    name: "Gambia",
    dialCode: "+220",
    isoCode: "GM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gm.svg",
  },
  {
    name: "Georgia",
    dialCode: "+995",
    isoCode: "GE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ge.svg",
  },
  {
    name: "Germany",
    dialCode: "+49",
    isoCode: "DE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/de.svg",
  },
  {
    name: "Ghana",
    dialCode: "+233",
    isoCode: "GH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gh.svg",
  },
  {
    name: "Gibraltar",
    dialCode: "+350",
    isoCode: "GI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gi.svg",
  },
  {
    name: "Greece",
    dialCode: "+30",
    isoCode: "GR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gr.svg",
  },
  {
    name: "Greenland",
    dialCode: "+299",
    isoCode: "GL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gl.svg",
  },
  {
    name: "Grenada",
    dialCode: "+1473",
    isoCode: "GD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gd.svg",
  },
  {
    name: "Guadeloupe",
    dialCode: "+590",
    isoCode: "GP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gp.svg",
  },
  {
    name: "Guam",
    dialCode: "+1671",
    isoCode: "GU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gu.svg",
  },
  {
    name: "Guatemala",
    dialCode: "+502",
    isoCode: "GT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gt.svg",
  },
  {
    name: "Guernsey",
    dialCode: "+44",
    isoCode: "GG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gg.svg",
  },
  {
    name: "Guinea",
    dialCode: "+224",
    isoCode: "GN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gn.svg",
  },
  {
    name: "Guinea-Bissau",
    dialCode: "+245",
    isoCode: "GW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gw.svg",
  },
  {
    name: "Guyana",
    dialCode: "+592",
    isoCode: "GY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gy.svg",
  },
  {
    name: "Haiti",
    dialCode: "+509",
    isoCode: "HT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ht.svg",
  },
  {
    name: "Holy See (Vatican City State)",
    dialCode: "+379",
    isoCode: "VA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/va.svg",
  },
  {
    name: "Honduras",
    dialCode: "+504",
    isoCode: "HN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hn.svg",
  },
  {
    name: "Hong Kong",
    dialCode: "+852",
    isoCode: "HK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hk.svg",
  },
  {
    name: "Hungary",
    dialCode: "+36",
    isoCode: "HU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/hu.svg",
  },
  {
    name: "Iceland",
    dialCode: "+354",
    isoCode: "IS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/is.svg",
  },
  {
    name: "India",
    dialCode: "+91",
    isoCode: "IN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/in.svg",
  },
  {
    name: "Indonesia",
    dialCode: "+62",
    isoCode: "ID",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/id.svg",
  },
  {
    name: "Iran",
    dialCode: "+98",
    isoCode: "IR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ir.svg",
  },
  {
    name: "Iraq",
    dialCode: "+964",
    isoCode: "IQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/iq.svg",
  },
  {
    name: "Ireland",
    dialCode: "+353",
    isoCode: "IE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ie.svg",
  },
  {
    name: "Isle of Man",
    dialCode: "+44",
    isoCode: "IM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/im.svg",
  },
  {
    name: "Israel",
    dialCode: "+972",
    isoCode: "IL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/il.svg",
  },
  {
    name: "Italy",
    dialCode: "+39",
    isoCode: "IT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/it.svg",
  },
  {
    name: "Ivory Coast / Cote d'Ivoire",
    dialCode: "+225",
    isoCode: "CI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ci.svg",
  },
  {
    name: "Jamaica",
    dialCode: "+1876",
    isoCode: "JM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jm.svg",
  },
  {
    name: "Japan",
    dialCode: "+81",
    isoCode: "JP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jp.svg",
  },
  {
    name: "Jersey",
    dialCode: "+44",
    isoCode: "JE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/je.svg",
  },
  {
    name: "Jordan",
    dialCode: "+962",
    isoCode: "JO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/jo.svg",
  },
  {
    name: "Kazakhstan",
    dialCode: "+77",
    isoCode: "KZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kz.svg",
  },
  {
    name: "Kenya",
    dialCode: "+254",
    isoCode: "KE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ke.svg",
  },
  {
    name: "Kiribati",
    dialCode: "+686",
    isoCode: "KI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ki.svg",
  },
  {
    name: "Korea, Democratic People's Republic of Korea",
    dialCode: "+850",
    isoCode: "KP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kp.svg",
  },
  {
    name: "Korea, Republic of South Korea",
    dialCode: "+82",
    isoCode: "KR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kr.svg",
  },
  {
    name: "Kosovo",
    dialCode: "+383",
    isoCode: "XK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/xk.svg",
  },
  {
    name: "Kuwait",
    dialCode: "+965",
    isoCode: "KW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kw.svg",
  },
  {
    name: "Kyrgyzstan",
    dialCode: "+996",
    isoCode: "KG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kg.svg",
  },
  {
    name: "Laos",
    dialCode: "+856",
    isoCode: "LA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/la.svg",
  },
  {
    name: "Latvia",
    dialCode: "+371",
    isoCode: "LV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lv.svg",
  },
  {
    name: "Lebanon",
    dialCode: "+961",
    isoCode: "LB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lb.svg",
  },
  {
    name: "Lesotho",
    dialCode: "+266",
    isoCode: "LS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ls.svg",
  },
  {
    name: "Liberia",
    dialCode: "+231",
    isoCode: "LR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lr.svg",
  },
  {
    name: "Libya",
    dialCode: "+218",
    isoCode: "LY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ly.svg",
  },
  {
    name: "Liechtenstein",
    dialCode: "+423",
    isoCode: "LI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/li.svg",
  },
  {
    name: "Lithuania",
    dialCode: "+370",
    isoCode: "LT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lt.svg",
  },
  {
    name: "Luxembourg",
    dialCode: "+352",
    isoCode: "LU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lu.svg",
  },
  {
    name: "Macau",
    dialCode: "+853",
    isoCode: "MO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mo.svg",
  },
  {
    name: "Madagascar",
    dialCode: "+261",
    isoCode: "MG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mg.svg",
  },
  {
    name: "Malawi",
    dialCode: "+265",
    isoCode: "MW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mw.svg",
  },
  {
    name: "Malaysia",
    dialCode: "+60",
    isoCode: "MY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/my.svg",
  },
  {
    name: "Maldives",
    dialCode: "+960",
    isoCode: "MV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mv.svg",
  },
  {
    name: "Mali",
    dialCode: "+223",
    isoCode: "ML",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ml.svg",
  },
  {
    name: "Malta",
    dialCode: "+356",
    isoCode: "MT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mt.svg",
  },
  {
    name: "Marshall Islands",
    dialCode: "+692",
    isoCode: "MH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mh.svg",
  },
  {
    name: "Martinique",
    dialCode: "+596",
    isoCode: "MQ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mq.svg",
  },
  {
    name: "Mauritania",
    dialCode: "+222",
    isoCode: "MR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mr.svg",
  },
  {
    name: "Mauritius",
    dialCode: "+230",
    isoCode: "MU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mu.svg",
  },
  {
    name: "Mayotte",
    dialCode: "+262",
    isoCode: "YT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/yt.svg",
  },
  {
    name: "Mexico",
    dialCode: "+52",
    isoCode: "MX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mx.svg",
  },
  {
    name: "Micronesia, Federated States of Micronesia",
    dialCode: "+691",
    isoCode: "FM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/fm.svg",
  },
  {
    name: "Moldova",
    dialCode: "+373",
    isoCode: "MD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/md.svg",
  },
  {
    name: "Monaco",
    dialCode: "+377",
    isoCode: "MC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mc.svg",
  },
  {
    name: "Mongolia",
    dialCode: "+976",
    isoCode: "MN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mn.svg",
  },
  {
    name: "Montenegro",
    dialCode: "+382",
    isoCode: "ME",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/me.svg",
  },
  {
    name: "Montserrat",
    dialCode: "+1664",
    isoCode: "MS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ms.svg",
  },
  {
    name: "Morocco",
    dialCode: "+212",
    isoCode: "MA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ma.svg",
  },
  {
    name: "Mozambique",
    dialCode: "+258",
    isoCode: "MZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mz.svg",
  },
  {
    name: "Myanmar",
    dialCode: "+95",
    isoCode: "MM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mm.svg",
  },
  {
    name: "Namibia",
    dialCode: "+264",
    isoCode: "NA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/na.svg",
  },
  {
    name: "Nauru",
    dialCode: "+674",
    isoCode: "NR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nr.svg",
  },
  {
    name: "Nepal",
    dialCode: "+977",
    isoCode: "NP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/np.svg",
  },
  {
    name: "Netherlands",
    dialCode: "+31",
    isoCode: "NL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nl.svg",
  },
  {
    name: "Netherlands Antilles",
    dialCode: "+599",
    isoCode: "AN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/an.svg",
  },
  {
    name: "New Caledonia",
    dialCode: "+687",
    isoCode: "NC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nc.svg",
  },
  {
    name: "New Zealand",
    dialCode: "+64",
    isoCode: "NZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nz.svg",
  },
  {
    name: "Nicaragua",
    dialCode: "+505",
    isoCode: "NI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ni.svg",
  },
  {
    name: "Niger",
    dialCode: "+227",
    isoCode: "NE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ne.svg",
  },
  {
    name: "Nigeria",
    dialCode: "+234",
    isoCode: "NG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ng.svg",
  },
  {
    name: "Niue",
    dialCode: "+683",
    isoCode: "NU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nu.svg",
  },
  {
    name: "Norfolk Island",
    dialCode: "+672",
    isoCode: "NF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/nf.svg",
  },
  {
    name: "North Macedonia",
    dialCode: "+389",
    isoCode: "MK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mk.svg",
  },
  {
    name: "Northern Mariana Islands",
    dialCode: "+1670",
    isoCode: "MP",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mp.svg",
  },
  {
    name: "Norway",
    dialCode: "+47",
    isoCode: "NO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/no.svg",
  },
  {
    name: "Oman",
    dialCode: "+968",
    isoCode: "OM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/om.svg",
  },
  {
    name: "Pakistan",
    dialCode: "+92",
    isoCode: "PK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pk.svg",
  },
  {
    name: "Palau",
    dialCode: "+680",
    isoCode: "PW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pw.svg",
  },
  {
    name: "Palestine",
    dialCode: "+970",
    isoCode: "PS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ps.svg",
  },
  {
    name: "Panama",
    dialCode: "+507",
    isoCode: "PA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pa.svg",
  },
  {
    name: "Papua New Guinea",
    dialCode: "+675",
    isoCode: "PG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pg.svg",
  },
  {
    name: "Paraguay",
    dialCode: "+595",
    isoCode: "PY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/py.svg",
  },
  {
    name: "Peru",
    dialCode: "+51",
    isoCode: "PE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pe.svg",
  },
  {
    name: "Philippines",
    dialCode: "+63",
    isoCode: "PH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ph.svg",
  },
  {
    name: "Pitcairn",
    dialCode: "+872",
    isoCode: "PN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pn.svg",
  },
  {
    name: "Poland",
    dialCode: "+48",
    isoCode: "PL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pl.svg",
  },
  {
    name: "Portugal",
    dialCode: "+351",
    isoCode: "PT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pt.svg",
  },
  {
    name: "Puerto Rico",
    dialCode: "+1939",
    isoCode: "PR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pr.svg",
  },
  {
    name: "Qatar",
    dialCode: "+974",
    isoCode: "QA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/qa.svg",
  },
  {
    name: "Reunion",
    dialCode: "+262",
    isoCode: "RE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/re.svg",
  },
  {
    name: "Romania",
    dialCode: "+40",
    isoCode: "RO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ro.svg",
  },
  {
    name: "Russia",
    dialCode: "+7",
    isoCode: "RU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ru.svg",
  },
  {
    name: "Rwanda",
    dialCode: "+250",
    isoCode: "RW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/rw.svg",
  },
  {
    name: "Saint Barthelemy",
    dialCode: "+590",
    isoCode: "BL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/bl.svg",
  },
  {
    name: "Saint Helena, Ascension and Tristan Da Cunha",
    dialCode: "+290",
    isoCode: "SH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sh.svg",
  },
  {
    name: "Saint Kitts and Nevis",
    dialCode: "+1869",
    isoCode: "KN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/kn.svg",
  },
  {
    name: "Saint Lucia",
    dialCode: "+1758",
    isoCode: "LC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lc.svg",
  },
  {
    name: "Saint Martin",
    dialCode: "+590",
    isoCode: "MF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/mf.svg",
  },
  {
    name: "Saint Pierre and Miquelon",
    dialCode: "+508",
    isoCode: "PM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/pm.svg",
  },
  {
    name: "Saint Vincent and the Grenadines",
    dialCode: "+1784",
    isoCode: "VC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vc.svg",
  },
  {
    name: "Samoa",
    dialCode: "+685",
    isoCode: "WS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ws.svg",
  },
  {
    name: "San Marino",
    dialCode: "+378",
    isoCode: "SM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sm.svg",
  },
  {
    name: "Sao Tome and Principe",
    dialCode: "+239",
    isoCode: "ST",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/st.svg",
  },
  {
    name: "Saudi Arabia",
    dialCode: "+966",
    isoCode: "SA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sa.svg",
  },
  {
    name: "Senegal",
    dialCode: "+221",
    isoCode: "SN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sn.svg",
  },
  {
    name: "Serbia",
    dialCode: "+381",
    isoCode: "RS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/rs.svg",
  },
  {
    name: "Seychelles",
    dialCode: "+248",
    isoCode: "SC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sc.svg",
  },
  {
    name: "Sierra Leone",
    dialCode: "+232",
    isoCode: "SL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sl.svg",
  },
  {
    name: "Singapore",
    dialCode: "+65",
    isoCode: "SG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sg.svg",
  },
  {
    name: "Sint Maarten",
    dialCode: "+1721",
    isoCode: "SX",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sx.svg",
  },
  {
    name: "Slovakia",
    dialCode: "+421",
    isoCode: "SK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sk.svg",
  },
  {
    name: "Slovenia",
    dialCode: "+386",
    isoCode: "SI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/si.svg",
  },
  {
    name: "Solomon Islands",
    dialCode: "+677",
    isoCode: "SB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sb.svg",
  },
  {
    name: "Somalia",
    dialCode: "+252",
    isoCode: "SO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/so.svg",
  },
  {
    name: "South Africa",
    dialCode: "+27",
    isoCode: "ZA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/za.svg",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    dialCode: "+500",
    isoCode: "GS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gs.svg",
  },
  {
    name: "South Sudan",
    dialCode: "+211",
    isoCode: "SS",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ss.svg",
  },
  {
    name: "Spain",
    dialCode: "+34",
    isoCode: "ES",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/es.svg",
  },
  {
    name: "Sri Lanka",
    dialCode: "+94",
    isoCode: "LK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/lk.svg",
  },
  {
    name: "Sudan",
    dialCode: "+249",
    isoCode: "SD",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sd.svg",
  },
  {
    name: "Suriname",
    dialCode: "+597",
    isoCode: "SR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sr.svg",
  },
  {
    name: "Svalbard and Jan Mayen",
    dialCode: "+47",
    isoCode: "SJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sj.svg",
  },
  {
    name: "Sweden",
    dialCode: "+46",
    isoCode: "SE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/se.svg",
  },
  {
    name: "Switzerland",
    dialCode: "+41",
    isoCode: "CH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ch.svg",
  },
  {
    name: "Syrian Arab Republic",
    dialCode: "+963",
    isoCode: "SY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/sy.svg",
  },
  {
    name: "Taiwan",
    dialCode: "+886",
    isoCode: "TW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tw.svg",
  },
  {
    name: "Tajikistan",
    dialCode: "+992",
    isoCode: "TJ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tj.svg",
  },
  {
    name: "Tanzania, United Republic of Tanzania",
    dialCode: "+255",
    isoCode: "TZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tz.svg",
  },
  {
    name: "Thailand",
    dialCode: "+66",
    isoCode: "TH",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/th.svg",
  },
  {
    name: "Timor-Leste",
    dialCode: "+670",
    isoCode: "TL",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tl.svg",
  },
  {
    name: "Togo",
    dialCode: "+228",
    isoCode: "TG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tg.svg",
  },
  {
    name: "Tokelau",
    dialCode: "+690",
    isoCode: "TK",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tk.svg",
  },
  {
    name: "Tonga",
    dialCode: "+676",
    isoCode: "TO",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/to.svg",
  },
  {
    name: "Trinidad and Tobago",
    dialCode: "+1868",
    isoCode: "TT",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tt.svg",
  },
  {
    name: "Tunisia",
    dialCode: "+216",
    isoCode: "TN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tn.svg",
  },
  {
    name: "Turkey",
    dialCode: "+90",
    isoCode: "TR",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tr.svg",
  },
  {
    name: "Turkmenistan",
    dialCode: "+993",
    isoCode: "TM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tm.svg",
  },
  {
    name: "Turks and Caicos Islands",
    dialCode: "+1649",
    isoCode: "TC",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tc.svg",
  },
  {
    name: "Tuvalu",
    dialCode: "+688",
    isoCode: "TV",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/tv.svg",
  },
  {
    name: "Uganda",
    dialCode: "+256",
    isoCode: "UG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ug.svg",
  },
  {
    name: "Ukraine",
    dialCode: "+380",
    isoCode: "UA",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ua.svg",
  },
  {
    name: "United Arab Emirates",
    dialCode: "+971",
    isoCode: "AE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ae.svg",
  },
  {
    name: "United Kingdom",
    dialCode: "+44",
    isoCode: "GB",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/gb.svg",
  },
  {
    name: "United States",
    dialCode: "+1",
    isoCode: "US",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/us.svg",
  },
  {
    name: "United States Minor Outlying Islands",
    dialCode: "+246",
    isoCode: "UMI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/umi.svg",
  },
  {
    name: "Uruguay",
    dialCode: "+598",
    isoCode: "UY",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/uy.svg",
  },
  {
    name: "Uzbekistan",
    dialCode: "+998",
    isoCode: "UZ",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/uz.svg",
  },
  {
    name: "Vanuatu",
    dialCode: "+678",
    isoCode: "VU",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vu.svg",
  },
  {
    name: "Venezuela, Bolivarian Republic of Venezuela",
    dialCode: "+58",
    isoCode: "VE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ve.svg",
  },
  {
    name: "Vietnam",
    dialCode: "+84",
    isoCode: "VN",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vn.svg",
  },
  {
    name: "Virgin Islands, British",
    dialCode: "+1284",
    isoCode: "VG",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vg.svg",
  },
  {
    name: "Virgin Islands, U.S.",
    dialCode: "+1340",
    isoCode: "VI",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/vi.svg",
  },
  {
    name: "Wallis and Futuna",
    dialCode: "+681",
    isoCode: "WF",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/wf.svg",
  },
  {
    name: "Yemen",
    dialCode: "+967",
    isoCode: "YE",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/ye.svg",
  },
  {
    name: "Zambia",
    dialCode: "+260",
    isoCode: "ZM",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/zm.svg",
  },
  {
    name: "Zimbabwe",
    dialCode: "+263",
    isoCode: "ZW",
    flag: "https://cdn.kcak11.com/CountryFlags/countries/zw.svg",
  },
];

export const donationReceiptForm = [
  {
    title: "Upload Donation Receipt",
    is_accordian: true,
    help_title: "Upload Donation Receipt",
    inputs: [
      {
        title: "Upload donation proof for someone else",
        other_inputs: [],
        input_type: "checkbox",
        input_slug: "upload_donation_proof_for_someone_else",
        regex_string: "",
        tooltip_description: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "7c0a8822-73b7-4e7b-9114-863678740787",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Mode of Payment",
        other_inputs: [],
        input_type: "select",
        input_slug: "mode_of_payment",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "d16b18cb-2a1a-4583-bdeb-15eee2596b51",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Donation date",
        other_inputs: [],
        input_type: "date",
        input_slug: "donation_date",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 0,
        max: 0,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "cd07958b-846a-42a8-baf5-14f60c2f164e",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Amount",
        other_inputs: [],
        input_type: "number",
        input_slug: "amount",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: true,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 50,
        max: 100000000,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "3d69369e-cd5f-468f-956b-6f7e9c13ae65",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "First Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "first_name",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "upload_donation_proof_for_someone_else",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "c0a0fab2-6a94-41b7-9223-614fecaa97ea",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Last Name",
        other_inputs: [],
        input_type: "string",
        input_slug: "last_name",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "upload_donation_proof_for_someone_else",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "true",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 3,
        max: 100,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "5103f60a-3e4d-4138-b25c-7659b9bd649f",
        prefix: "",
        suffix: "",
        key: "",
        is_dependant: true,
        haveError: false,
      },
      {
        title: "Enter Phone Number",
        other_inputs: [],
        input_type: "string",
        input_slug: "enter_phone_number",
        regex_string: "",
        tooltip_description: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: true,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 15,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "aa8f1478-cda8-4921-abe3-9957a5922a66",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Email ID",
        other_inputs: [],
        input_type: "email",
        input_slug: "email_id",
        regex_string: "",
        tooltip_description: "",
        is_required: false,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: true,
        is_beside_main_title: false,
        other_label_value:
          "Provide email so when receipt is validated we will send receipt on mail.",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 5,
        max: 30,
        options: [""],
        file_type: "",
        file_size: 0,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "ee188ed3-1afe-4d4c-af11-127ea12b8fac",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
      {
        title: "Upload donation proof",
        other_inputs: [],
        input_type: "file",
        input_slug: "upload_donation_proof",
        regex_string: "",
        tooltip_description: "",
        is_required: true,
        add_suffix: false,
        is_disabled: false,
        is_other_dependant: false,
        is_other_lable: false,
        is_beside_main_title: false,
        other_label_value: "",
        min_duration_minute: "",
        display_joint_name: false,
        merge_string: "",
        string_to_be_replace: "",
        second_merge_string: "",
        dependant_type: "",
        is_date_greater: false,
        greater_than_date: "",
        other_dependant_type: "",
        dependant_value: "",
        other_dependant_value: "",
        is_accordian: true,
        is_display: false,
        is_mobile: false,
        is_bold: false,
        is_inline: false,
        other_input: false,
        min: 1,
        max: 1,
        options: [""],
        file_type: "photo",
        file_size: 20,
        video_duration: 0,
        file_access_type: [],
        file_tag: "",
        num_of_row: 0,
        multiselect: false,
        id: "eadcc858-31fd-48f7-928b-2534232a26de",
        prefix: "",
        suffix: "",
        key: "",
        haveError: false,
      },
    ],
    id: "dc88bc02-bebb-4fb3-ae3a-892ddcbfcc63",
    visible: ["guest", "donor", "user", "volunteer", "ngo", "corporate"],
    haveError: false,
  },
];
