import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";

// Initialize Firebase

const firebaseConfig = {
  apiKey: "AIzaSyDEuKSaurTVN1MHHE7QHEgzmsclmHyTDKw",
  authDomain: "saayam-15d29.firebaseapp.com",
  projectId: "saayam-15d29",
  storageBucket: "saayam-15d29.appspot.com",
  messagingSenderId: "719163764814",
  appId: "1:719163764814:web:a83e07d71ebbdcc733531f",
  measurementId: "G-GZBPHBP5KQ",
};

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();

const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const githubAuthProvider = new firebase.auth.GithubAuthProvider();
const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
const twitterAuthProvider = new firebase.auth.TwitterAuthProvider();

export {
  auth,
  googleAuthProvider,
  githubAuthProvider,
  facebookAuthProvider,
  twitterAuthProvider,
  firebaseConfig,
};
